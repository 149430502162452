import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import _ from 'lodash'
import {Typography, IconButton, Grid, InputLabel, Select, FormControl, ButtonGroup,  withStyles, MenuItem, Hidden, Popover } from "@material-ui/core"
import {
  AjaxTable,
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem, 
  MultiSelect
} from 'components';
import {
  Add,
} from '@material-ui/icons'
import EditPOItems from 'views/Dialogs/EditPOItems'
import EditPODetail from 'views/Dialogs/EditPODetail'
import TransferUnplanDialog from 'views/Dialogs/Inbound/TransferUnplan'
import AddComment from 'views/Dialogs/AddComment'
import DataContext from 'context/Data'
import utils from 'utils/utils'
import moment from 'moment';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  cardContainer: {
    height: '800px',
    // width: '100%'
  },
  toolBar: {
    display: 'flex',
    width: '100%',
    paddingBottom: '.5rem'
  },
  exportRecord: {
    marginLeft: 'auto'
  },
  sectionTitle: {
    margin: '1rem 0',
    paddingBottom: '.625rem'
  }
});

const statusMap = {
  OPEN: {
    value: 'OPEN',
    label: 'OPEN',
  },
  RECEIVING: {
    value: 'RECEIVING',
    label: 'RECEIVING',
  },
  CLOSED: {
    value: 'CLOSED',
    label: 'CLOSED',
  },
};

const showCustomerComments = {
  '221': true,
}

class ManagePO extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    // search field input refs for uncontrolled 
    this.searchByInput = null;
    this.searchForInput = null;

    this.current_page = 0;
    this.updatePage = null;

    this.displayDB = false;

    this.state = {
      loading: false,
      poList: [],
      selectedPO: {},
      dialog: '',
      singlePO: null,
      actionLog: null,
      comment: null,
      renderingAjax: true,
      singleActionsAnchor: null,
      uplan_list: [],
      displayFilters: {
        status: {
          OPEN: true,
          RECEIVING: true,
          CLOSED: true,
        },
      },
    };

    document.title = "Manage PO";
  }

  componentWillMount() {
    this.loadUnplanPOList(); // load unplan
    let po_id = localStorage.getItem("dbPO");
    if (po_id) {
      this.searchPO(po_id);
      this.displayDB = true;
      localStorage.removeItem("dbPO");
    } else {
      this.loadPOByPage();
    }
  }

  // uplan PO to match
  loadUnplanPOList = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/receiving`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true});
    
    req.then(this.loadUnplanPOListSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  loadUnplanPOListSuccess = (resp) => {
    // this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let options = [];
    for (let po of resp.data.unplan) {
        options.push({
          label: po['PO'],
          value: po['id']
        });
    }
    if (!options || !Array.isArray(options)) return;
    this.setState({uplan_list: options});
  }
  loadPOByPage = (page, updatePage) => {
    this.updatePage = updatePage;
    page = page || 0;
  
    // let req = axios({
    //   method: 'get',
    //   url: `${utils.getBaseUrl('customer')}/po/${page}`,
    //   headers: {
    //     token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
    //   },
    // });

    // needs a new api to load po page by filters
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/pofilter`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: Object.assign({page}, this.state.displayFilters)
    });

    this.setState({loading: true});
    req.then(this.loadPOByPageSuccess.bind(this, page, updatePage)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  loadPOByPageSuccess = (newPage, updatePage, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    if (resp.data.length === 0) {
      this.context.alert("Already the last page.");
      return;
    }
   
    this.current_page = newPage;
    this.setState({poList: resp.data, selectedPO: {},});
    if (updatePage) updatePage();
  }
  searchPO = (po_id) => {
    let searchBy = this.searchByInput ? this.searchByInput.value : '';
    let searchFor = this.searchForInput ? utils.formatString(this.searchForInput.value, {multiline: true}) : '';

    if (po_id) {
      searchBy = 'po_id';
      searchFor = po_id;
    }

    this.displayDB = false;

    let req = axios({
      method: 'post',
      data: {
        type: searchBy,
        data: searchFor
      },
      url: `${utils.getBaseUrl('customer')}/po`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    // this.setState({loading: true, displayFilters: {
    //   status: {
    //     OPEN: true,
    //     RECEIVING: true,
    //     CLOSED: true,
    //   },
    // },});
    let successCallback = po_id ? this.searchPOByIDSuccess : this.searchPOSuccess;
    req.then(successCallback).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  searchPOByIDSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({singlePO: resp.data.po, actionLog: resp.data.action_log, comment: resp.data.comment});
  }
  searchPOSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.setState({
      poList: resp.data,
      renderingAjax: false,
      singlePO: null,
      actionLog: null,
      comment: null,
    });
  }
  updatePODetail = (newPO) => {
    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/po`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        po_id: this.state.singlePO.po_id,
        po: Object.assign(this.state.singlePO, newPO)
      },
    });
    this.setState({loading: true});
    req.then(this.updatePODetailSuccess.bind(this, Object.assign(this.state.singlePO, newPO))).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updatePODetailSuccess = (newPO, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Update PO detail success.");
    this.setState({singlePO: newPO, dialog: ''});
  }
  updatePOLineItems = (newItems) => {
    let req = axios({
      method: 'put',
      url: `${utils.getBaseUrl('customer')}/po`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        po_id: this.state.singlePO.po_id,
        item: newItems
      },
    });
    this.setState({loading: true});
    
    req.then(this.updatePOLineItemsSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  updatePOLineItemsSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Update PO items success.");
    this.setState({singlePO: resp.data.po, dialog: ''});
  }
  cancelPO = () => {
    let req = axios({
      method: 'delete',
      url: `${utils.getBaseUrl('customer')}/po/${this.state.singlePO?.po_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.cancelPOSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  cancelPOSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Cancel PO success.");
    window.location.reload();
  }
  closePO = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/closepo/${this.state.singlePO?.po_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.closePOSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  closePOSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let newPO =  Object.assign({}, this.state.singlePO);
    newPO.po_status = 'CLOSED';
    this.context.snackDisplay("Close PO success.");
    this.setState({singlePO: newPO, dialog: ''});
  }
  printReceivingFlag = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/receivingflag/${this.state.singlePO?.po_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.printReceivingFlagSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  printReceivingFlagSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    switch (true) {
      case typeof resp.data === "string":
        utils.printPage(resp.data);
        break;
      case Array.isArray(resp.data):
        resp.data.forEach((elem)=>{
            utils.printPage(elem);
        });
        break;
      case typeof resp.data === "object":
        for (let key in resp.data) {
            utils.printPage(resp.data[key]);
        }
        break;
    }
  }
  markPOIssue = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/flagpo/${this.state.singlePO?.po_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.markPOIssueSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  markPOIssueSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Flag PO success.");
    this.setState({singleActionsAnchor: null});
  } 
  resolvePOIssue = () => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/solvepo/${this.state.singlePO?.po_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.resolvePOIssueSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  resolvePOIssueSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Resolve PO issue success.");
    this.setState({singleActionsAnchor: null});
  }
  transferUnplan = (unPlanPO) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/transferpo/${this.state.singlePO?.po_id}/${unPlanPO.po_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true});
    req.then(this.transferUnplanSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  transferUnplanSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    this.context.snackDisplay("Transfer success.");
    this.setState({singleActionsAnchor: null, dialog: ''});
  }
  addComment = (newComment) => {
    if (newComment.type === "text") {
      this.addCommentText(newComment.text);
    } else {
      this.uploadPdf(newComment.formData);
    }
  }
  addCommentText = (comment_text) => {
    let req_data = {
      target_id: this.state.singlePO?.po_id,
      type: 'po',
      comment: comment_text
    };

    let req = axios({
      method: 'POST',
      url: `${utils.getBaseUrl('customer')}/comment`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: req_data,
    });

    this.setState({ loading: true });
    req.then(this.addCommentTextSuccess.bind(this, req_data)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  addCommentTextSuccess = (req_data, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (resp.data) {
      this.context.snackDisplay("Add PO comment success.");
      this.setState({comment: this.state.comment.concat([{
        comment: req_data.comment,
        date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        username: localStorage.getItem('username'),
        fullname: ''
      }])})
      this.setState({dialog: ''});
    } else {
      this.context.alert('Add PO comment error.');
    }
  }
  uploadPdf = (form_data) => {
    form_data.append('target_id', this.state.singlePO?.po_id);
    form_data.append('type', 'po');

    let req = axios({
      method: 'post',
      dataType: 'json',
      processData: false,
      contentType: false,
      cache: false,
      url: `${utils.getBaseUrl('customer')}/import/document`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: form_data,
    });

    this.setState({loading: true});
    req.then(this.uploadPdfSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  uploadPdfSuccess = (resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    if (resp.data) {
      this.context.snackDisplay("Add PO comment success.");
      this.setState({comment: this.state.comment.concat([{
        comment: resp.data,
        date: moment.utc().format('YYYY-MM-DD HH:mm:ss'),
        username: localStorage.getItem("username"),
        fullname: "",
      }])});
      this.setState({ dialog: "" });
    } else {
      alert("Upload PDF comment error!");
    }
  }
  exportCurrentPage = () => {
    if (!this.state.poList || this.state.poList.length == 0) { 
      this.context.alert('Nothing to export.');
      return;
    }

    let keyword_array = [];
    for (let single_po of this.state.poList) {
      keyword_array.push(single_po.po_id);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/exportreport`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'po',
        keyword: keyword_array
      },
    });

    this.setState({loading: true});
    req.then(this.exportPOSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  exportSelectedPO = () => {
    if (_.isEmpty(this.state.selectedPO)) {
      this.context.alert('No PO selected.');
      return;
    }

    let keyword_array = [];
    for (let index in this.state.selectedPO) {
      keyword_array.push(this.state.selectedPO[index]['po_id']);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/exportreport`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'po',
        keyword: keyword_array
      },
    });

    this.setState({loading: true});
    req.then(this.exportPOSuccess).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  exportPOSuccess = (resp) => {
    this.setState({ loading: false });
    // alert error if any
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;

    // window.open(result.url, '_blank');
    this.downloadFile(result.url);
  }
  downloadFile = async (downloadUrl) => {
    let req = axios({
      method: 'get',
      url: downloadUrl,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      }
    });

    this.setState({loading: true});
    req.then(this.downloadFileSuccess.bind(this, downloadUrl.substring(downloadUrl.lastIndexOf('/') + 1))).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  downloadFileSuccess = (file_name, response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file_name); // Name of the downloaded file
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object and remove the link
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      this.setState({ loading: false });
  }

  validateSearchForm = () => {
    let searchBy = this.searchByInput ? this.searchByInput.value : '';
    let searchFor = this.searchForInput ? utils.formatString(this.searchForInput.value) : '';

    let err = "";
    if (!searchFor) err += "Search For input field is empty";
    // if has error, display error and stop submit
    if (err) {
      this.context.alert(err);
      return;
    }
    // if no error, submit search
    this.searchPO();
  }
  onBackToListClick = () => {
    const {singlePO, poList} = this.state;
    let newPOList = poList.map((elem)=>{return (elem.po_it === singlePO.po_id) ? singlePO : elem});
    this.setState({singlePO: null, actionLog: null, comment: null, poList: newPOList});
    utils.scrollToTop();
  }

  // pass updated filters to backend using load page api
  filterPOByPage = (newDisplayFilter) => {
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/pofilter`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      // data: Object.assign({page: this.current_page}, newDisplayFilter)
      data: Object.assign({page: 0}, newDisplayFilter)
    });
    this.current_page = 0;

    this.setState({loading: true});
    req.then(this.filterPOByPageSuccess.bind(this, newDisplayFilter)).catch(utils.defaultErrorCallBack.bind(this, {thisContext: this, alert: this.context.alert}));
  }
  filterPOByPageSuccess = (newDisplayFilter, resp) => {
    this.setState({loading: false});
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    this.setState({poList: resp.data, displayFilters: Object.assign(this.state.displayFilters, newDisplayFilter), selectedPO: {}});

    if (resp.data.length == 0) {
      this.context.alert("No data found.");
      return;
    } 
  }

  // Filter PO utils
  applyFilter = (newVal, key) => {
    let newDisplayFilter = Object.assign({}, this.state.displayFilters);
    newDisplayFilter[key] = newVal;
    if (this.state.renderingAjax) { // if displaying PO page, use backend filter
      if (this.updatePage) this.updatePage(0); // if ajax filter changes, reset to page 1
      // this.filterPOByPage({[key]: newDisplayFilter[key]});
      this.filterPOByPage(newDisplayFilter);
    } else {
      this.setState({displayFilters: newDisplayFilter, selectedPO: {}});
    }
  }

  // Filter on Render
  getFilteredPOs = (displayFilters) => {
    if (!displayFilters) displayFilters = this.state.displayFilters;

    return this.state.poList.filter((elem)=>{
      return displayFilters['status'][elem['po_status']] !== false;
    });
  }

  renderPOList = () => {
    const {classes} = this.props;
    const {singlePO, selectedPO, displayFilters, renderingAjax} = this.state;
    if (singlePO) return;

    let rows = this.getFilteredPOs();
    
    let columns = [
      {
        key: 'po_key',
        label: 'PO #',
        width: 'auto',
        render: (val, key, po) => {
          return (
            <Button color='default' onClick={this.searchPO.bind(this, po.po_id)}>{val}</Button>
          );
        }
        // colDesc: 'This is the unique identifier of product, only allow numbers, string, underscore and hyphen',
      },
      {
        key: 'tracking',
        label: 'Tracking',
        width: 150,
        // colDesc: 'This is the unique identifier of product, only allow numbers, string, underscore and hyphen',
      },
      {
        key: 'estimated_delivery',
        label: 'Estimated Delivery',
      },
      {
        key: 'received',
        label: 'Total Received',
      },
      {
        key: 'discrepancy',
        label: 'Discrepancy',
      },
      {
        key: 'po_status',
        label: 'Status',
      },
      {
        key: 'created_dt',
        label: 'Created Date',
        render: utils.localizeDate,
        width: 'auto',
        contentNoWrap: true,
      },
      {
        key: 'arrival_dt',
        label: 'Arrival Date',
        render: utils.localizeDate,
        contentNoWrap: true,
      },
      {
        key: 'received_dt',
        label: 'Received Date',
        contentNoWrap: true,
        render: utils.localizeDate
      },
    ];
    let statusOptions = [];
    for (let filterKey in displayFilters['status']) {
      let option = statusMap[filterKey]; 
      statusOptions.push({selected: displayFilters['status'][filterKey], value: option.value, label: option.label});
    }

    let poListTable = (
      <AjaxTable
        rows={rows}
        rowHeight={'auto'} 
        id='table-export'
        columns={columns}
        handlePageChange={this.loadPOByPage}
      />
    );
    if (!renderingAjax) {
      poListTable = (
        <NewDataTable
          rows={rows}
          id='table-export'
          rowHeight={'auto'} 
          columns={columns}
          rowSelection={{
            selected: selectedPO, // since ajax table will not have sort by, data array index will always work for selection
            handleSelectedChange: (newSelected) => (this.setState({selectedPO: newSelected})),
            uniqueID: 'po_id'
          }}
        />
      );
    }

    return (
      <GridItem xs={12} sm={12} md={12}>
        <CardContainer>
          <div className={classes.toolBar}>
            <ButtonGroup>
              <MultiSelect
                title="Status"
                listItems={statusOptions}
                handleSubmit={(val)=>{this.applyFilter(val, 'status')}}
              />
            </ButtonGroup>

            {rows.length > 0 && <Button className={classes.exportRecord} onClick={()=>{
              if (renderingAjax) this.exportCurrentPage();
              else this.exportSelectedPO();
            }}>{renderingAjax ? 'Export Page' : 'Export Selected'}</Button>}
          </div>

          {poListTable}
        </CardContainer>
      </GridItem>
    );
  }
  renderPODetail = () => {
    const {classes} = this.props;
    const {singlePO, actionLog, comment, singleActionsAnchor} = this.state;

    if (!singlePO) return null;

    let propertyList = [
      {
        key: 'po_key',
        label: 'PO #',
      },
      {
        key: 'tracking',
        label: 'Tracking',
        width: 150,
      },
      {
        key: 'estimated_delivery',
        label: 'Estimated Delivery',
      },
      {
        key: 'po_status',
        label: 'Status',
      },
      {
        key: 'created_dt',
        label: 'Created Date',
        width: 'auto',
        contentNoWrap: true,
        render: utils.localizeDate
      },
      {
        key: 'arrival_dt',
        label: 'Arrival Date',
        width: 'auto',
        contentNoWrap: true,
        // render: utils.localizeDate
      },
      {
        key: 'received_dt',
        label: 'Received Date',
        width: 'auto',
        contentNoWrap: true,
        render: utils.localizeDate
      },
      {
        key: 'flag_po',
        label: 'Flag PO',
        width: 'auto',
        contentNoWrap: true,
      },
      {
        key: 'reconciled_dt',
        label: 'Reconciled Date',
        width: 'auto',
        contentNoWrap: true,
        render: utils.localizeDate
      },
    ];
    
    let commentRows = comment ? Array.from(comment) : [];
    commentRows.push({
      fullname: localStorage.getItem('username'),
      add_new: 'text',
      comment: "",
    });

    let po_items = singlePO.item ? Array.from(singlePO.item) : [];
    let item_total = {
      total: true,
      sku: '',
      upc: '',
      name: '',
      quantity: 0,
      received: 0,
      discrepancy: 0,
    };
    po_items.forEach((elem)=>{
      item_total.quantity += parseInt(elem.quantity) || 0;
      item_total.received += parseInt(elem.received) || 0;
      item_total.discrepancy += parseInt(elem.discrepancy) || 0;
    });
    po_items.push(item_total);

    // PO action button list
    let singlePOActions = [];
    // Warehouse Actions
    if (this.context.roleType.get() == 'warehouse') {
      singlePOActions.push({
        label: 'Edit',
        onClick: ()=>{this.setState({dialog: 'editPO'})},
      }); // for warehuose, always show edit
      singlePOActions.push({
        label: 'Mark Issue',
        onClick: ()=>{this.context.confirm('Are you sure to mark this PO has issue?', this.markPOIssue)},
      }); // mark PO has issue
      if (singlePO.flag_po) {
        singlePOActions.push({
          label: 'Resolve Issue',
          onClick: ()=>{this.context.confirm('Are you sure to resolve the issue of this PO?', this.resolvePOIssue)},
        });
      } // If PO has issue, show resolve
      singlePOActions.push({
        label: 'Transfer Unplan',
        onClick: ()=>this.setState({dialog: 'transfer_unplan'}),
      }); // Transfer Unplan
      // Hide print receiving flag for now
      // if (singlePO.po_status !== 'OPEN') {
      //   singlePOActions.push({
      //   label: 'Print Receiving Flag',
      //   onClick: this.printReceivingFlag,
      //   });
      // } // If PO is not open, been handled, print receiving flag
    } else {
    // Customer Actions 
      // If PO open, show edit
      if (singlePO.po_status === 'OPEN') {
        singlePOActions.push({
          label: 'Edit',
          onClick: ()=>{this.setState({dialog: 'editPO'})},
        });
      }
    }
    // Common Action For warehuose and Customer, check status, Cancel and Close
    if (singlePO.po_status === 'OPEN') {
      singlePOActions.push({
        label: 'Cancel',
        onClick: ()=>{this.context.confirm('Are you sure to cancel this PO?', this.cancelPO)},
      });
    }
    if (singlePO.po_status === 'RECEIVING') {
      singlePOActions.push({
        label: 'Close',
        onClick: ()=>{this.context.confirm('Are you sure to close this PO?', this.closePO)},
      });
    }

    return (
      <React.Fragment>     
        <GridItem xs={12} sm={12} md={12}>
          <CardContainer>
            <div className={classes.toolBar}>
              {/* Back To List */}
              {!this.displayDB && <Button color="bxzDefault" variant="contained" style={{marginRight: '1rem'}} onClick={this.onBackToListClick}>Go Back</Button>}   

              {/* PO Edit Actions */}
              <Hidden lgDown>
                <ButtonGroup>   
                  {singlePOActions.map((btn)=><Button key={btn.label} color="bxzDefault" variant="contained" onClick={btn.onClick}>{btn.label}</Button>)}
                </ButtonGroup>
              </Hidden>
              {/* PO Edit Actions Dropdown */}
              <Hidden xlUp>
                <Button color="bxzDefault" variant="contained" onClick={(e)=>{this.setState({singleActionsAnchor: this.state.singleActionsAnchor ? null : e.currentTarget});}}>More Actions</Button>               
                <Popover
                  open={Boolean(singleActionsAnchor)}
                  anchorEl={singleActionsAnchor}
                  onClose={()=>this.setState({singleActionsAnchor: null})}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                >     
                  {singlePOActions.map((btn)=> <MenuItem key={btn.label} onClick={()=>{
                    this.setState({singleActionsAnchor: null});
                    btn.onClick();
                  }}>{btn.label}</MenuItem>)}
                </Popover>
              </Hidden>
            </div>
            <NewDataTable
              rows={[singlePO]}
              noPagination
              columns={propertyList}
            />
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <CardContainer>
            {/* check po status */}
            {
              (singlePO.po_status === 'OPEN') && <div><Button onClick={()=>this.setState({dialog: 'editItem'})}>Edit Item</Button></div>
            }

            <NewDataTable
              rows={po_items}
              // rowHeight={38}
              maxHeight={500}
              noPagination
              columns={[
                {
                  key: 'index',
                  label: 'Index',
                  render: (key, value, elem, index) => elem.total ? 'Total' : index + 1
                },{
                  key: 'sku',
                  label: 'SKU',
                },{
                  key: 'upc',
                  label: 'UPC'
                }, {
                  key: 'name',
                  label: 'Item Name',
                  width: 'auto',
                }, {
                  key: 'pallet',
                  label: 'Pallet',
                }, {
                  key: 'quantity',
                  label: 'Quantity',
                }, {
                  key: 'received',
                  label: 'Received',
                }, {
                  key: 'discrepancy',
                  label: 'Discrepancy',
                },
              ]}
            />
          </CardContainer>
        </GridItem>

        <GridItem xs={12} sm={6} md={6}>
          <CardContainer>
            <Typography variant="h6">Action Logs</Typography>
            <NewDataTable
              rows={actionLog || []}
              noPagination
              maxHeight={500}
              columns={[
                {
                  key: 'fullname',
                  label: 'User',
                },{
                  key: 'notes',
                  label: 'Notes'
                }, {
                  key: 'created_dt',
                  label: 'Date/Time',
                  render: utils.localizeDate
                }
              ]}
            />     
          </CardContainer>
        </GridItem>

        {
          (utils.getRoleType() != 'customer' || showCustomerComments[localStorage.getItem('customer_id')]) && <GridItem xs={12} sm={6} md={6}>
            <CardContainer>
              <Typography variant="h6">Comments</Typography>
              <NewDataTable
                // rowHeight={38}
                rows={commentRows}
                noPagination
                columns={[
                  {
                    // key: 'fullname',
                    key: 'username',
                    label: 'User',
                  },{
                    key: 'comment',
                    label: 'Comment',
                    render: (val, key, row) => {
                      if (row.add_new) {
                        return (
                          <IconButton onClick={()=>{this.setState({dialog: 'addComment'})}} size="small" style={{backgroundColor: "#3f51b5", marginLeft: ".5rem"}} variant="fab" aria-label="Add">
                            <Add style={{color: 'white'}}/>
                          </IconButton>
                          // <IconButton onClick={()=>{this.setState({dialog: 'addComment'})}} variant="fab" size="small" aria-label="PDF">
                          //   <Add />
                          // </IconButton>
                        );
                      } else {
                        let content = val ? val : '';
                        if (content.includes("https://")) {
                          let btn_text = "See PDF";
                          if (content.includes(".pdf")) 
                          {
                            btn_text = "See PDF";
                          } else 
                          {
                            btn_text = "See Image";
                          }
                          let file_name = content.split("import/");
                          if (file_name.length > 1)
                          {
                            btn_text = utils.removeFilePrefix(file_name[1]);
                          } 


                          return (<Button onClick={()=>{
                              let newTab = window.open(content);
                              if (!newTab) this.context.alert("Unable to open new tab, please change browser settings to allow pop-up.");
                          }}>{btn_text}</Button>);
                        }
                        return content;
                      }
                    }
                  }, {
                    key: 'date',
                    label: 'Date/Time',
                    render: utils.localizeDate
                  }
                ]}
              />
            </CardContainer>
          </GridItem>
        }
      </React.Fragment>
    );
  }
  renderDialog = () => {
    const {dialog, singlePO, comment} = this.state;
    switch (dialog) {
      case 'addComment':
        return (
          <AddComment 
            handleSubmit={(newComment)=>{this.addComment(newComment)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editPO':
        return (
          <EditPODetail
            po={singlePO}
            handleSubmit={(newPO)=>{this.updatePODetail(newPO)}}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'editItem':
        return (
          <EditPOItems 
            lineItems={this.state.singlePO?.item}
            handleSubmit={(newLineItems)=>this.updatePOLineItems(newLineItems)}
            handleClose={()=>{this.setState({dialog: ''})}}
          />
        );
        break;
      case 'transfer_unplan':
          return (
            <TransferUnplanDialog
              handleClose={()=>{this.setState({dialog: '', singleActionsAnchor: null,})}}
              handleSubmit={this.transferUnplan}
              po_list={this.state.uplan_list}
              po={singlePO.po_key}
            />
          );
    }
  }

  render() {
    const {classes} = this.props; 
    const {loading} = this.state;

    return (
      <Grid className={classes.root} container spacing={2}>
        {loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}
              
        {this.renderDialog()}
        
        <GridItem xs={12} sm={6} md={3}>
          <CardContainer>
            <div>
            <FormControl fullWidth required className={classes.customInput}>
                <InputLabel shrink>Search By</InputLabel>
                <Select
                  defaultValue="po"
                  inputRef={(el)=>{this.searchByInput = el}}
                >
                  <MenuItem value='po'>PO #</MenuItem>
                  <MenuItem value='sku'>SKU</MenuItem>
                  <MenuItem value='tracking'>Tracking #</MenuItem>
                </Select>
              </FormControl>
              
              <CustomInput
                 labelText='Search For'
                 labelProps={{shrink: true}}
                 formControlProps={{
                   fullWidth: true,
                   required: true,
                   className: this.props.classes.customInput
                 }}
                 inputProps={{
                  inputRef: (el)=>{this.searchForInput = el},
                    defaultValue: "",
                    placeholder: 'Use Linebreak to separate multiple keywords',
                    multiline: true,
                    rows: 3
                 }}
              />
              
              <div className={classes.toolBar} style={{marginTop: '.5rem'}}>
                <Button onClick={this.validateSearchForm}>Search</Button>
              </div>
            </div>
          </CardContainer>
        </GridItem>

        {this.renderPODetail()}
        {this.renderPOList()}
      </Grid>
    );
  }
}
export default withStyles(styles)(ManagePO);
