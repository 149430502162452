import React, {useState, useContext} from 'react'
import DataContext from 'context/Data'
import utils from 'utils/utils'
import {makeStyles, Grid, InputLabel, Select, MenuItem, FormControl} from '@material-ui/core'

import {CustomInput, BasicDialog, Button} from 'components'

const useStyles = makeStyles((theme)=>({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  customInput: {
    marginBottom: theme.spacing(.5)
  },
}))

export default function EditUser(props) {
  const classes = useStyles();
  const dataContext = useContext(DataContext);
  const {user: defaultUser, role_options, closeDialog, onSubmit} = props;
  const [user, setUser] = useState(defaultUser);

  function onUserChange(key, newVal) {
    let newUser = Object.assign({}, user);
    newUser[key] = newVal;
    setUser(newUser);
  }

  function renderFormContent() {
    const {user_role, username, password, email} = user;

    return (
      <Grid container className={classes.root} spacing={2}>
        <FormControl required fullWidth className={classes.selectInput}>
          <InputLabel shrink>Role</InputLabel>
          <Select
            value={user_role || ''}
            onChange={(e)=>{onUserChange('user_role', e.target.value)}}
          >
            {
              role_options.map((val)=>{
                return (<MenuItem key={val.role_id} value={parseInt(val.role_id)}>{val.role_name}</MenuItem>);
              })
            }
              {/* <MenuItem value='4'>Employee</MenuItem>
              <MenuItem value='8'>Manager</MenuItem> */}
          </Select>
        </FormControl>

        <CustomInput
          labelText='Username'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{onUserChange('username', e.target.value)},
            value: username || '',
          }}
        />

        {/* <CustomInput
          labelText='Password'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{onUserChange('password', e.target.value)},
            value: password,
          }}
        /> */}

        <CustomInput
          labelText='Email'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>{onUserChange('email', e.target.value)},
            value: email || '',
          }}
        />
      </Grid>
    );
  }
  function handleSubmit() {
    let err = '';
    
    if (!user.user_role) err += 'User role is required. \n';
    // if (!user.password) err += 'Password is required.\n';
    if (!user.username) err += 'Username is required. \n';
    else if (!utils.verifyString(user.username)) {
      err += 'Username can only contain letters, numbers, and email, no other special characters or wihtespace.\n';
    }
    if (user.username.length < 6) err += 'Username should have at least 6 characters!\n';
    if (!user.email) err += 'Email is required. \n';
    else {
      let email_valid = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z{|}~])*@[a-zA-Z0-9](-?[a-zA-Z0-9])*(\.[a-zA-Z](-?[a-zA-Z0-9])*)+$/g;
      if (!email_valid.test(user.email)) err += 'Email address is invalid. \n';
    }

    if (err) dataContext.alert(err);
    else onSubmit(user);
  }

  let title = "Edit User";
  if (!defaultUser.user_id) title = 'New User';

  return (
    <BasicDialog
      fullWidth
      maxWidth='sm'
      open
      onClose={closeDialog}
      header={title}
      content={renderFormContent()}
      actions={[
        <Button key="cancel" color='default' onClick={closeDialog}>Cancel</Button>,
        <Button key="submit" onClick={handleSubmit}>Submit</Button>
      ]}
    />
  );
}