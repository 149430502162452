import React from 'react'
import _ from 'lodash'
import axios from 'axios';
import moment from 'moment-timezone'

// World and US map shapes   https://www.npmjs.com/package/world-atlas
import MapUS from 'utils/Maps/states-10m.json' 
import MapWorld from 'utils/Maps/countries-50m.json'
import Chart from 'chart.js/auto' // Chart.js Feature
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { topojson, ChoroplethController, GeoFeature, ColorScale, ProjectionScale } from 'chartjs-chart-geo';

const shippingMethodMap = {
  "BXZ.PKP": "Pack and Hold",
  "BXZ.DONOTSHIP": "Boxzooka Cancel and Return",
  "BXZ.PKP.KIT": "Boxzooka Kit Project",
  "BXZ.USA.1": "Boxzooka 1-Day",
  "BXZ.USA.2": "Boxzooka 2-Day",
  "BXZ.USA.3": "Boxzooka 3-Day",
  "BXZ.USA.5": "Boxzooka 5-Day",
  "BXZ.USA.7": "Boxzooka 7-Day",
  "BXZ.SAMEDAY.NYC": "BXZ Same Day",
  "UPS.DOM.1": "UPS Next Day Air",
  "UPS.DOM.2": "UPS Second Day Air",
  "UPS.DOM.3": "UPS 3-Day Air",
  "UPS.EXP.1" : 'UPS Next Day Air Early',
  "UPS.GRD.RESI": "UPS Ground",
  "SUREPOST": "UPS Surepost",
  // No DHL anymore
  "DHLEC.MAX": "DHL 3-Day Priority",
  "DHLEC.GRD": "DHL Ground",
  // "DHLEC.STD.GRD": "DHL Standard Ground Shipping",
  "DHLEC.PLT": "International Standard (4-12 Business Days)",
  // "DHLEC.BPM": "DHL Bound Printed Matter Service",
  // "DHLEC.SAMEDAY": "DHL Same Day",
  // "DHLEC.NEXTDAY": "DHL Next Day",
  // No DHL anymore end
  "FDX.DOM.1": "FedEx Next Day Air",
  "FDX.DOM.2": "FedEx Second Day Air",
  "FDX.DOM.3": "FedEx 3-Day Air",
  "FDX.GRD": "FedEx Ground",
  "FDX.GRD.COLLECT": "FEDEX GROUND (COLLECT) B2B ONLY",
  "FDX.HOME": "FedEx Home",
  "SMARTPOST": "FedEx SmartPost",
  "FDX.EXP.1": 'FedEx Next Day Air Early',
  // right now using FedEx international
  "UPS.INTL.EXP": "UPS International Expedited (2-5 Business Days)",
  "FDX.INTL.ECO": "FedEx International Economy (2-5 Business Days)",
  "FDX.CONNECT": "FedEx International Connect (7-15 Business Days)",
  "USPS.PRIORITY": 'USPS Priority',
  "USPS.PARCEL": 'USPS ParcelSelect',
  "USPS.FIRST": 'USPS First',
  "USPS.EXPRESS": "USPS Express",
  "USPS.INTL.PACKAGE": "USPS International Standard",
 };
const orderStatusMap = {
  // 2:"Received",
  // 3:"Deleted",
  // 5: "On Hold",
  // 6:"Cancelled",
  // 7:"Picking",
  // 8:"Picked",
  // 9:"Shipped",
  // 12:"Verified",
  // 13:"Returned",
  // 14: "Inbound",
  2:"UNBATCH",
  4: "HOLDING",
  5: "BACKORDER",
  6:"CANCELLED",
  7:"PICKING",
  8:"PICKED",
  9:"SHIPPED",
  12:"VERIFIED",
  13:"RETURNED",
  14: "INBOUND",
};
const defaultReturnReasonOptions = [
  {value: "none",  label: "None"},
  {value: "wrong_size",  label: "Wrong Size"},
  {value: "changed_my_mind",  label: "Changed My Mind"},
  {value: "created_by_mistake",  label: "Created By Mistake"},
  {value: "exchange",  label: "Exchange"},
  {value: "product_fit",  label: "Product fit"},
  {value: "not_match",  label: "Doesn't match detail"},
  {value: "stained_damaged",  label: "Product is stained or damaged"},
  {value: "gift",  label: "It was a gift"},
  {value: "return to sender",  label: "Return to sender"},
];
const reasonOptionMap = {
  'GOOD': [
	{value: "none",  label: "None"},
	{value: "factory_sealed",  label: "Factory Sealed"},
	// {value: "wrong_size",  label: "Wrong Size"},
	// {value: "changed_my_mind",  label: "Changed My Mind"},
	// {value: "created_by_mistake",  label: "Created By Mistake"},
	// {value: "exchange",  label: "Exchange"},
	// {value: "product_fit",  label: "Product fit"},
	// {value: "not_match",  label: "Doesn't match detail"},
	// {value: "gift",  label: "It was a gift"},
	// {value: "return to sender",  label: "Return to sender"},
  ],
  'CLEAN': [
	{value: "none",  label: "None"},
	{value: "wrong_size",  label: "Wrong Size"},
	{value: "changed_my_mind",  label: "Changed My Mind"},
	{value: "created_by_mistake",  label: "Created By Mistake"},
	{value: "exchange",  label: "Exchange"},
	{value: "product_fit",  label: "Product fit"},
	{value: "not_match",  label: "Doesn't match detail"},
	{value: "gift",  label: "It was a gift"},
	{value: "return to sender",  label: "Return to sender"},
  ],
  'DAMAGED': [
	{value: "dirty_stained",  label: "Dirty / Stained"},
	{value: "ripped_torn_cut",  label: "Ripped / Torn / Cut"},
	{value: "stretched_out",  label: "Stretched Out"},
	{value: "missing_component",  label: "Missing Component"},
	{value: "faded_color_texture",  label: "Faded Color / Texture"},
	{value: "customer_cuttag",  label: "Customer Cut Tag"},
	{value: "damaged_packaging",  label: "Damaged Packaging"},   
	{value: "fabric_stretched",  label: "Fabric Stretched"},   
	{value: "odor",  label: "Odor"},
	{value: "broken",  label: "Broken"},
  ],
};

class Utils {
	constructor() {
		this.defaultShippingMethodOptions = [
		  {method: "BXZ.PKP",  name: "Pack and Hold"},
		  {method: "BXZ.PKP.KIT",  name: "Boxzooka Kit Project"},
		  {method: "BXZ.USA.1",  name: "Boxzooka 1-Day"},
		  {method: "BXZ.USA.2",  name: "Boxzooka 2-Day"},
		  {method: "BXZ.USA.3",  name: "Boxzooka 3-Day"},
		  {method: "BXZ.USA.5",  name: "Boxzooka 5-Day"},
		  {method: "BXZ.USA.7",  name: "Boxzooka 7-Day"},
		  {method: "BXZ.SAMEDAY.NYC",  name: "BXZ Same Day"},
		  {method: "UPS.DOM.1",  name: "UPS Next Day Air"},
		  {method: "UPS.DOM.2",  name: "UPS Second Day Air"},
		  {method: "UPS.DOM.3",  name: "UPS 3-Day Air"},
		  {method: "UPS.EXP.1",  name: "UPS Next Day Air Early"},
		  {method: "UPS.GRD.RESI",  name: "UPS Ground"},
		  {method: "SUREPOST",  name: "UPS Surepost"},
		  {method: "FDX.DOM.1",  name: "FedEx Next Day Air"},
		  {method: "FDX.DOM.2",  name: "FedEx Second Day Air"},
		  {method: "FDX.DOM.3",  name: "FedEx 3-Day Air"},
		  {method: "FDX.GRD",  name: "FedEx Ground"},
		  {method: "FDX.GRD.COLLECT",  name: "FEDEX GROUND (COLLECT) B2B ONLY"},
		  {method: "FDX.HOME",  name: "FedEx Home"},
		  {method: "SMARTPOST",  name: "FedEx SmartPost"},
		  {method: "FDX.EXP.1",  name: "FedEx Next Day Air Early"},
		  {method: "UPS.INTL.EXP",  name: "UPS International Expedited (2-5 Business Days)"},
		  {method: "FDX.INTL.ECO",  name: "FedEx International Economy (2-5 Business Days)"},
		  {method: "FDX.CONNECT",  name: "FedEx International Connect (7-15 Business Days)"},
		  {method: "USPS.PRIORITY",  name: "USPS Priority"},
		  {method: "USPS.PARCEL",  name: "USPS ParcelSelect"},
		  {method: "USPS.FIRST",  name: "USPS First"},
		  {method: "USPS.EXPRESS",  name: "USPS Express"},
		  {method: "USPS.INTL.PACKAGE",  name: "USPS International Standard"},
		];
		// CASE StatusId WHEN 2 THEN 'UNBATCH' WHEN 7 THEN 'PICKING' WHEN 8 THEN 'PICKED' WHEN 4 THEN 'HOLDING' WHEN 12 THEN 'VERIFIED' WHEN 6 THEN 'CANCELLED' WHEN 5 THEN 'BACKORDER' WHEN 9 THEN 'SHIPPED' WHEN 13 THEN 'RETURNED' END as status

		// Chart.js 
		this.MapUS = MapUS; // Map data used by Chart.js Geo map
		this.MapWorld = MapWorld;
		Chart.register(ChoroplethController, GeoFeature, ColorScale, ProjectionScale); // register controller in chart.js and ensure the defaults are set
		this.Chart = Chart;
		this.ChartDataLabels = ChartDataLabels;
		this.topojson = topojson;
		this.mapNation = topojson.feature(MapUS, MapUS.objects.nation).features[0];
        this.mapStates = topojson.feature(MapUS, MapUS.objects.states).features;

	}
	removeFilePrefix(str) {
		// 正则表达式匹配下划线前的任意长度数字和固定长度的日期部分，不包括下划线
		const regex = /^\d+_\d{14}/;
		// 使用 replace 方法删除匹配的前缀
		return str.replace(regex, '');
	}
	getReturnReasonOptions(condition) {
	  // todo, might load from backend and save to localstorage in the future
	  if (condition) return reasonOptionMap[condition];
	  else return defaultReturnReasonOptions;
	}
	checkCustomerWarehouseChanged(contextData) {
	  let err = '';
	  if (parseInt(contextData.customer.get()) != parseInt(localStorage.getItem('customer_id'))) {
		err += "Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again! \n";
	  }
	  if (parseInt(contextData.warehouse.get()) != parseInt(localStorage.getItem('warehouse_id'))) {
		err += "Warehouse has been changed to " + localStorage.getItem('warehouse_name') + ", please refresh the page and try again! \n";
	  }
	  return err;
	}
	is_obj_equal(obj1, obj2) {
		// Implement a deep comparison algorithm to check if the content of two objects is equal
        // This is just a simple example, you may need to adjust it based on your requirements
        return JSON.stringify(obj1) === JSON.stringify(obj2);
	}
	/**
	 * Get base url of current domain for api call.
	 * 
	 * void
	*/
	getRoleType() {
	  return localStorage.getItem('role_type');   
	}
	checkAllowCopy() {
		let allowed_roles = {
			'SystemAdmin': true,
			'MANAGERFACILITY': true,
			'CustomerService': true,
			'DRIVER': true,
		};
		return allowed_roles[localStorage.getItem('role')];
	}
	getBaseUrl(api_prefix) {
	  switch (api_prefix) {
		case 'customer':
		  api_prefix = '/api/customer/v2';
		  break;
		case 'warehouse':
		  api_prefix = '/api/wms/v1';
		  break;
		default:
		  api_prefix = '';
	  }
	  let base = window.location.origin;
	  // Set root path for debug environment
	  if (base.includes("http://localhost:")) base = "https://sandboxtest.boxzooka.com";
	  // if (base.includes("http://localhost:")) base = "https://developer.boxzooka.com";
	  // if (base.includes("http://localhost:")) base = "https://sandboxclient.boxzooka.com";
	  return base + api_prefix;
	}
	/**
	 * Default ajax error handler. When it is used, need to bind this context to the caller component.
	 *
	 * @param {object} options The this context of caller, usually refers to the Component
	 *  @param {function} alert The function alert message replace the default system alert
	 *  @param {function} errorCallback The function to be executed at the begining of the error handler
	 * @param {object} err The err response
	*/
	defaultErrorCallBack(options, err) {
	  if (this?.setState) this.setState({loading: false}); // When it is used, this context is binded to the caller component.
	  if (options.errorCallback) options.errorCallback();
	  let pop_up_message = window.alert; // default browser alert function
	  if (options.snackDisplay) {
		pop_up_message = options.snackDisplay; // display snackbar which won't affect display
	  } else if (options.alert) {
		pop_up_message = options.alert;  // display alert dialog which Will affect display
	  }

	  if (err.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		console.log(err.response);
		switch (err.response.status) {
		  case 401: // Login Session expired, alert messag and log out
			if (options.alert) { // Use overwrigen alert function
			  options.alert('Session expired, please login again.', {
				type: "Logged out", 
				disableBackdropClick: true,
				onCloseCallback: ()=>{
				  localStorage.clear();
				  window.location.reload();
				}
			  });
			  return;
			} else { // Use system default alert function
			  window.alert('Session expired, please login again.');
			  localStorage.clear();
			  window.location.reload();
			  return;
			}
		  case 404: 
			pop_up_message('API Url not found, need to check request url!', {severity: 'error'});
			break;
		  case 500:
			pop_up_message("Bad request, please verify the input!", {severity: 'error'});
			break;
		  default:
			let errMsg = err.response.data.Error;
			console.log(errMsg);
			if (!errMsg) errMsg = err.message || "Internal server error.";
			pop_up_message(errMsg, {severity: 'error'});
		}
	  } else if (err.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		console.log(err.request);
		pop_up_message('Network issue, no response.', {severity: 'error'});
	  } else {
		// Something happened in setting up the request that triggered an Error
		console.log('Error', err.message);
		pop_up_message(err.message, {severity: 'error'});
	  }
	}
	/**
	 * Validate if password pass the format requirements.
	 *
	 * @param {string} pwd The input password
	*/
	passwordValidator (pwd) {
	  let password_value = pwd ? pwd : "";
	  let requirements = {
		charLength: function() {
		  if( password_value.length >= 8 ) return false;
		  else return "Password length should be >= 8.";
		},
		illegalChar: function() {
		  let regex = /["' ]/g;
		  if( regex.test(password_value) ) return "Password should NOT contain space or quotes.";
		  else return false;
		},
		lowercase: function() {
		  let regex = /^(?=.*[a-z]).+$/;
		  if( regex.test(password_value) ) return false;
		  else return "Password should contain at lease one lowercase letter.";
		},
		uppercase: function() {
		  let regex = /^(?=.*[A-Z]).+$/;
		  if( regex.test(password_value) ) return false;
		  else return "Password should contain at least one uppercase letter."
		},
		special: function() {
		  let regex = /^(?=.*[0-9_\W]).+$/;
		  if( regex.test(password_value) ) return false;
		  else return "Password should contain at least a number or a special symbol."
		}
	  };
	  let errors = [];
	  for (let key in requirements) {
		let validator = requirements[key];
		let error = validator();
		if (error) errors.push(error);
	  }
	  return errors;
	}
	/**
	 * Validate if input is non-empty.
	 *
	 * @param {string} value The input value
	 * @param {string} key The input value
	*/
	validateRequiredInput (value, key) {
	  let err = "";
	  if (!value) err += (key ? key : "This field") + " is required. \n";
	  else if (!this.formatString(value)) err += key + " is invalid. \n";
	  return err;
	}
	/**
	 * Test if a date is between a Date range.
	 *
	 * @param {string} input The date string to be tested. It is usually a MM/DD/YYYY format
	 * @param {object} options The specification object. This includes some function running specifications which most of the time, can be optional.
	 * options.start, options.end, in this case, are the date range string, in MM/DD/YYYY format.
		* @param {string} options.start default is today, inclusive
		* @param {string} options.end   default is 12/31/2099
	*/
	isDateInRange(input, options) {
		options = options || {};
		let start = options.start || moment().subtract(1, "days"); // defualt range start to doday (inclusive)
		let end = options.end ? options.end : '2099-12-31'; // default range end to 2099-12-31
		input = moment(moment(input).format("YYYY-MM-DD"));

		return input.isBetween(start, end);
	}
	/**
	 * Format and localize date. This is for table cell value usage
	 *
	 * @param {string} input The table cell date value
	*/
	localizeDate (input) {
	  if (!input) return '';
	
	  let newDt = moment.utc(input);
	  newDt = newDt.local();
	
	  return newDt.format('YYYY-MM-DD HH:mm:ss');
	}
	/**
	 * format date, also can convert to local time.
	 *
	 * @param {string} input The input date value
	 * @param {string} displayFormat The date format string
	 * @param {bool} convertToLocal Whether to convert the date from UTC to Local time
	*/
	formatDateTime (input, displayFormat, convertToLocal) {
	  if (!input) return '';
	  if (!displayFormat) displayFormat = 'YYYY-MM-DD HH:mm:ss';
	
	  if (convertToLocal) {
		let newDt = moment.utc(input);
		newDt = newDt.local();
		return newDt.format(displayFormat);
	  } else {
		let newDt = moment(input);
		return newDt.format(displayFormat);
	  }
	}
	/**
	 * Convert a number to percentage.
	 *
	 * @param {string} input The input float number
	*/
	formatPercentage (input, fixed_digit) {
	  if (fixed_digit == undefined) fixed_digit = 2;
	  if (isNaN(parseFloat(input))) return input;
	  input = parseFloat(input*100);
	  return (input.toFixed(fixed_digit)+'%');
	}
	verifyString(str, options) {
		options = options || {};
		let regex = options.regex ? options.regex : /^[A-Za-z0-9@._-]+$/;
		return regex.test(str);
	}
	/**
	 * Format a date string, remove quotes, or other chars if specified.
	 *
	 * @param {string} input The date string to be tested. 
	 * @param {object} options The specification object. This includes some function running specifications which most of the time, can be optional.
	 * options.start, options.end, in this case, are the date range string, in MM/DD/YYYY format.
		* @param {bool} options.replace_whitespace replace whitespace in the input string. To make string work in url
		* @param {bool} options.multiline the input string has multiple lines, concat it to single line with ,
		* @param {regex} options.format_regex regular expression to remove certain characters from the input. Defualt to remove ' and " (quotes)
	*/
	formatString(input, options) {
		options = options || {};
		if (!input) return input;
		if (typeof input === "number") input = '' + input;
		if (typeof input !== "string") return input; // if input is not string, don't convert it
		// input = typeof input === "string" ? input : ""; 

		// if (options.replace_whitespace) input = input.replace(/[ ]/g, "+");

		// /[^a-zA-Z-_0-9]/g
		// /[^A-Za-z0-9 %!\@#$%:\[\]^&_*()-.=`~,]/g
		let format_regex  = options.format_regex || /["']/g;

		if (options.multiline) {
			// the string to be formatted is multi line, split by \n
			// after format, concat it by , symbol
			// may need to allow custom separator later
			let result = input;
			try {
				result = input.trim();
				// result = result.replace(/["']/g, "");
				// result = result.split('\n').map((val)=>{return val.trim()}).join('\n');
				result = result.split('\n').map((val)=>{
					val = val.replace(format_regex, "").trim();
					if (options.replace_whitespace) val = val.replace(/[ ]/g, "+");
					return val;
				}).join(',');
				result = result.split(',').filter((v)=>v!=='').join(',');
			}
			catch (err) {
				console.log(err);
			}
			return result;
		} else {
			let result = input;
			try {
				result = result.trim();
				if (options.replace_whitespace) result = result.replace(/[ ]/g, "+");
				result =  result.replace(format_regex, "");
			}
			catch (err) {
				console.log(err);
			}
			return result;
		}
	}
	convertMonth(input) {
		if (typeof input !== "string") return input;
		
		// map 3-digit to Full
		let monthMap = {
            JAN: "January",
            FEB: "February",
            MAR: "March",
            APR: "April",
            MAY: "May",
            JUN: "June",
            JUL: "July",
            AUG: "August",
            SEP: "September",
            OCT: "October",
            NOV: "November",
            DEC: "December",
        };
		
		if (monthMap[input.toUpperCase()]) return monthMap[input.toUpperCase()];
		else if (monthMap[input.substr(0, 3).toUpperCase()]) {
			// if input is full month, return first 3 digits
			return input.substr(0, 3);
		}

        return input;
	}
	/**
     * Convert State between 2 digit code and full name.
     *
     * @param {string} val The input value
    */
    convertState (val) {
		// https://www.faa.gov/air_traffic/publications/atpubs/cnt_html/appendix_a.html
		// https://stackoverflow.com/questions/15598584/how-can-i-select-every-other-line-with-multiple-cursors-in-sublime-text
		const short_to_full = {
		  'AL':'Alabama',
		  'KY':'Kentucky',
		  'OH':'Ohio',
		  'AK':'Alaska',
		  'LA':'Louisiana',
		  'OK':'Oklahoma',
		  'AZ':'Arizona',
		  'ME':'Maine',
		  'OR':'Oregon',
		  'AR':'Arkansas',
		  'MD':'Maryland',
		  'PA':'Pennsylvania',
		  'AS':'American Samoa',
		  'MA':'Massachusetts',
		  'PR':'Puerto Rico',
		  'CA':'California',
		  'MI':'Michigan',
		  'RI':'Rhode Island',
		  'CO':'Colorado',
		  'MN':'Minnesota',
		  'SC':'South Carolina',
		  'CT':'Connecticut',
		  'MS':'Mississippi',
		  'SD':'South Dakota',
		  'DE':'Delaware',
		  'MO':'Missouri',
		  'TN':'Tennessee',
		  'DC':'District of Columbia',
		  'MT':'Montana',
		  'TX':'Texas',
		  'FL':'Florida',
		  'NE':'Nebraska',
		  'TT':'Trust Territories',
		  'GA':'Georgia',
		  'NV':'Nevada',
		  'UT':'Utah',
		  'GU':'Guam',
		  'NH':'New Hampshire',
		  'VT':'Vermont',
		  'HI':'Hawaii',
		  'NJ':'New Jersey',
		  'VA':'Virginia',
		  'ID':'Idaho',
		  'NM':'New Mexico',
		  'VI':'Virgin Islands',
		  'IL':'Illinois',
		  'NY':'New York',
		  'WA':'Washington',
		  'IN':'Indiana',
		  'NC':'North Carolina',
		  'WV':'West Virginia',
		  'IA':'Iowa',
		  'ND':'North Dakota',
		  'WI':'Wisconsin',
		  'KS':'Kansas',
		  'MP':'Northern Mariana Islands',
		  'WY':'Wyoming',
		};   
		const full_to_short = {
		  'Alabama':'AL',
		  'Kentucky':'KY',
		  'Ohio':'OH',
		  'Alaska':'AK',
		  'Louisiana':'LA',
		  'Oklahoma':'OK',
		  'Arizona':'AZ',
		  'Maine':'ME',
		  'Oregon':'OR',
		  'Arkansas':'AR',
		  'Maryland':'MD',
		  'Pennsylvania':'PA',
		  'American Samoa':'AS',
		  'Massachusetts':'MA',
		  'Puerto Rico':'PR',
		  'California':'CA',
		  'Michigan':'MI',
		  'Rhode Island':'RI',
		  'Colorado':'CO',
		  'Minnesota':'MN',
		  'South Carolina':'SC',
		  'Connecticut':'CT',
		  'Mississippi':'MS',
		  'South Dakota':'SD',
		  'Delaware':'DE',
		  'Missouri':'MO',
		  'Tennessee':'TN',
		  'District of Columbia':'DC',
		  'Montana':'MT',
		  'Texas':'TX',
		  'Florida':'FL',
		  'Nebraska':'NE',
		  'Trust Territories':'TT',
		  'Georgia':'GA',
		  'Nevada':'NV',
		  'Utah':'UT',
		  'Guam':'GU',
		  'New Hampshire':'NH',
		  'Vermont':'VT',
		  'Hawaii':'HI',
		  'New Jersey':'NJ',
		  'Virginia':'VA',
		  'Idaho':'ID',
		  'New Mexico':'NM',
		  'Virgin Islands':'VI',
		  'Illinois':'IL',
		  'New York':'NY',
		  'Washington':'WA',
		  'Indiana':'IN',
		  'North Carolina':'NC',
		  'West Virginia':'WV',
		  'Iowa':'IA',
		  'North Dakota':'ND',
		  'Wisconsin':'WI',
		  'Kansas':'KS',
		  'Northern Mariana Islands':'MP',
		  'Wyoming':'WY',
		};
		switch (true) {
		  case !!short_to_full[val]:
			val = short_to_full[val];
			break;
		  case !!short_to_full[val.toUpperCase()]:
			val = short_to_full[val.toUpperCase()];
			break;
		  case !!full_to_short[val]:
			val = full_to_short[val];
			break;
		  case !!full_to_short[val.toUpperCase()]:
			val = full_to_short[val.toUpperCase()]
			break;
		  default:
			// don't convert;
		}
		return val;
	}
	/**
	 * Format a number with comma separated.
	 *
	 * @param {string} input The input number to be formatted. 
	 * @param {number} fixedDigit The how many digits wanna keep for float
	*/
	numberWithComma(input, fixedDigit) {
	  if (input === "N/A") return input;
	  input = parseFloat(input) ? parseFloat(input) : parseFloat("0");
	  if (fixedDigit) input = input.toFixed(fixedDigit);
	  input = '' + input;
	  let parts = input.toString().split(".");
	  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	  return parts.join(".");
	}
	/**
	 * Export table to csv file.
	 *
	 * @param {string} tableId The id of the table to be exported, used for css query selector . 
	 * @param {string} fileName The csv file name
	 * @param {bool} headerOnly The only export the header, no table body
	*/
	exportTableToCsv (tableId, fileName, headerOnly) {
	  let csv = [];
	  if (!tableId) tableId = 'table-export';
	  // set default file name
	  if (!fileName) fileName = 'Exported_File';
  
	  // if given file name doesn't contain extension .csv append
	  if (fileName.indexOf('.csv') === -1) {
		fileName += '.csv';
	  }
	
	  let rows = document.querySelectorAll("table#" + tableId + " tr");
	  for (let i = 0; i < rows.length; i++) {
		let selector = "td, th";
		if (headerOnly) selector = 'th';
		let row = [], cols = rows[i].querySelectorAll(selector);
		for (let j = 0; j < cols.length; j++){
		  row.push(cols[j].innerText.replace('\n', '').replace(/,/g, '').trim());
		}
		csv.push(row.join(","));
	  }
	  // Download CSV
	  // this.download_csv(csv.join("\n"), filename);
	  csv = csv.filter((elem)=>!!elem); // remove empty lines
	  csv = csv.join("\n");
	
	
	  let csvFile;
	  let downloadLink;
	  // CSV FILE
	  csvFile = new Blob([csv], {type: "text/csv"});
	  // Download link
	  downloadLink = document.createElement("a");
	  // File name
	  downloadLink.download = fileName;
	  // We have to create a link to the file
	  downloadLink.href = window.URL.createObjectURL(csvFile);
	  // Make sure that the link is not displayed
	  downloadLink.style.display = "none";
	  // Add the link to your DOM
	  document.body.appendChild(downloadLink);
	  // Lanzamos
	  downloadLink.click();
	} 
	/**
	 * Export an array to csv file.
	 *
	 * @param {array} array The data array to be exported. 
	 * @param {array} headers The csv headers, also includes some render functions
	 * @param {array} fileName The csv file name
	*/
	exportArrayToCsv (array, headers, fileName) {
	  // each array element should be an object
	  // each object should be a simple object, that means each property value should be a string/number
	  // the headers is the property list, need to follow the order of header to access the object value
	  let csv = [];
	  // set default file name
	  if (!fileName) fileName = 'Exported_File';
	  // if given file name doesn't contain extension .csv append
	  if (fileName.indexOf('.csv') === -1) {
		fileName += '.csv';
	  }
	
	  if (!array || !headers || headers.length === 0) {
		csv = [];
	  } else {
		csv.push(headers.map(elem=>{return elem.label}).join(','));
		for (let elem of array) {
		  let text_array = [];
		  for (let header of headers) {
			// let val = (''+elem[header.key]).replace('\n', '').replace(',', ' ').trim();
			let val = elem[header.key];
			if (val === null) val = '';
			if (header.onDisplay) val = header.onDisplay(val, header.key, elem);
			else if (header.render) val = typeof header.render(val, header.key, elem) === 'object' ? val : header.render(val, header.key, elem); // if return function returns an object, don't use it
			val = (''+val).replace('\n', '').replace(/,/g, '').trim();
			text_array.push(val);
		  }
		  csv.push(text_array.join(','));
		}
	  }
	  // Download CSV
	  // this.download_csv(csv.join("\n"), filename);
	  csv = csv.join("\n");
	
	  let csvFile;
	  let downloadLink;
	  // CSV FILE
	  csvFile = new Blob([csv], {type: "text/csv"});
	  // Download link
	  downloadLink = document.createElement("a");
	  // File name
	  downloadLink.download = fileName;
	  // We have to create a link to the file
	  downloadLink.href = window.URL.createObjectURL(csvFile);
	  // Make sure that the link is not displayed
	  downloadLink.style.display = "none";
	  // Add the link to your DOM
	  document.body.appendChild(downloadLink);
	  // Lanzamos
	  downloadLink.click();
	}
	/**
	 * Scroll page to top. (only apply to the outer container)
	 * void
	*/
	scrollToTop() {
	  window.location = '#';
	  document.getElementById('page-content-container').scrollTop = 0;
	}
	scrollToElement(elem) {
	  document.getElementById(elem).scrollIntoView();
	}
	/**
	 * Pop up a window to print the given html body text.
	 *
	 * @param {string} printText Html text to be printed. 
	 * @param {string} style The css style text 
	 * @param {bool} autoPrint Boolean controls whether the page automatically prints
	*/
	printText(printText, style, autoPrint) {
	  if (style === undefined) style = "";
	  var w = window.open();
	  if (!w) {
		alert("To enable print, please allow pop up window");
		return;
	  }
	  var pageText = "<html><head><title></title><style>" + style + "</style></head><body>";
	  pageText += printText;
	  pageText += "</body>";
	  w.document.write(pageText);
	  if (autoPrint) {
		w.document.write("<scr" + "ipt>");
		w.document.write("function printPage(){window.print();window.close();}");
		w.document.write("setTimeout(printPage, 500)");
		w.document.write("</scr" + "ipt>");
	  }
	} // Deprecated
	printTable ({colSetting, data, style, dataHeader}) {
	  if (style === undefined) style = "";
	  var w = window.open();
	  // style = "body {margin: 0;padding: 0;text-align: center} img {transform:rotate(90deg);position: fixed;top: 3.2cm;left: -2.9cm;width: 16cm;height: 9.9cm;}";
	  style = "table {width: 100%; border-collapse: collapse;} td, th {border: 1px solid black; text-align: center; padding: .5rem;}";
	  var pageText = "<html><head><title></title><style>" + style + "</style></head><body>";
	  let printText = '';
	  if (dataHeader) pageText += dataHeader; 
	  console.log(colSetting, data);
	  let tableHeaderText = '';
	  for (let col of colSetting) {
		tableHeaderText += "<th>"+col.label+"</th>";
	  }
	  let tableBodyText = '';
	  for (let row of data) {
		tableBodyText += "<tr>";
		for (let col of colSetting) {
		  let cellContent = row[col.key];
		  if (!row[col.key] && row[col.key] != 0) cellContent = '';
		  tableBodyText += "<td>"+cellContent+"</td>";
		}
		tableBodyText += "</tr>";
	  }
	  printText = `
		<table>
		  <thead>
		  <tr>
			${tableHeaderText}
		  </tr>
		  </thead>
		  <tbody>
			${tableBodyText}
		  </tbody>
		</table>
	  `;
	  pageText += printText;
	  pageText += "</body>";
	  w.document.write(pageText);
	}
	/**
	 * Pop up a window to print the given html text. This contain the full html text including style and others
	 *
	 * @param {string} pageText Html text to be printed. 
	*/
	printPage (pageText) {
		let printWindow = window.open('', '_blank');
		// let w = window.open('', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
		if (!printWindow) {
			alert("To enable print, please allow pop up window");
			return;
		}
		printWindow.document.write(pageText);
		printWindow.document.close();
		// Check if the content is fully loaded and then call the print function
		// printWindow.onload = function() {
		// 	if (printWindow.document.readyState === 'complete') {
		// 		printWindow.print();
		// 		printWindow.onafterprint = function() {
		// 			printWindow.close();
		// 		};
		// 	} else {
		// 		printWindow.document.onreadystatechange = function() {
		// 			if (printWindow.document.readyState === 'complete') {
		// 				printWindow.print();
		// 				printWindow.onafterprint = function() {
		// 					printWindow.close();
		// 				};
		// 			}
		// 		};
		// 	}
		// };
	}
	/**
	 * Call backend api to print barcode
	 *
	 * @param {string} content Barcode content to be printed. 
	*/
	printBarcode (content) {
	  let base = window.location.origin;
	  if (base === "http://localhost:3000") base = 'https://sandboxtest.boxzooka.com';
	
	  // let url = base + '/packingid/' + content;
	  let url = base + '/api/customer/v1/packingid/' + content;
	  let w = window.open(url);
	  if (!w) {
		alert('To enable print, please allow pop up window');
	  }
	} // Should be Deprecated. Should not make ajax call here
	/**
	 * Play a warning sound to call attention when error happens
	 *
	 * void
	*/
	playSound () {
	  if (!window.AudioContext) window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
	  let audioContext = null;
	  try {
		audioContext = new window.AudioContext();
	  } catch (e) {
		console.log('Your browser does not support AudioContext!');
	  }
	
	  if (!audioContext) return;
	
	  let oscillator = audioContext ? audioContext.createOscillator() : null;
	  if (!audioContext || !oscillator) {
		if (!window.AudioContext) window.AudioContext = window.AudioContext || window.webkitAudioContext || window.mozAudioContext || window.msAudioContext;
		let audioContext = null;
		try {
		  audioContext = new window.AudioContext();
		  oscillator = audioContext ? audioContext.createOscillator() : null;
		} catch (e) {
		  console.log('!Your browser does not support AudioContext');
		  return;
		}
	  }
	  // 创建一个GainNode,它可以控制音频的总音量
	  let gainNode = audioContext.createGain();
	  // 把音量，音调和终节点进行关联
	  oscillator.connect(gainNode);
	  // audioContext.destination返回AudioDestinationNode对象，表示当前audio context中所有节点的最终节点，一般表示音频渲染设备
	  gainNode.connect(audioContext.destination);
	  // 指定音调的类型，其他还有square|triangle|sawtooth
	  oscillator.type = 'sine';
	  // 设置当前播放声音的频率，也就是最终播放声音的调调
	  oscillator.frequency.value = 493.88;
	  // 当前时间设置音量为0
	  gainNode.gain.setValueAtTime(0, audioContext.currentTime);
	  // 0.01秒后音量为1
	  gainNode.gain.linearRampToValueAtTime(40, audioContext.currentTime + 0.01);
	  // 音调从当前时间开始播放
	  oscillator.start(audioContext.currentTime);
	  // 1秒内声音慢慢降低，是个不错的停止声音的方法
	  // gainNode.gain.exponentialRampToValueAtTime(0.001, audioContext.currentTime + 2);
	  // 1秒后完全停止声音
	  oscillator.stop(audioContext.currentTime + 1.4);
	}
	/**
	 * Initialize google map api
	 *
	 * @param {function} callback Callback function when google map initialized. 
	*/
	initGoogleMap (callback) {
	  if (window.google) return;
	  let script = document.createElement('script');
	  console.log("Todo, might need to update API key");
	  script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC-KafwpmUT7lLrerRJTftjlF3gTL2WvLY&libraries=places&callback=initGoogleMap';
	  script.defer = true;
	  script.async = true;
	
	  // Attach your callback function to the `window` object
	  window.initGoogleMap = function() {
		// JS API is loaded and available
		if (callback) callback();
	  };
	  // Append the 'script' element to 'head'
	  document.head.appendChild(script);
	}
	/**
	 * Handle empty table cell data diaplay
	 *
	 * @param {any} val Table cell value. 
	*/
	tableCellOnDisplay (val) {
	  if (val !== '0' && val !== 0) {
		val = val ? val : "";
	  }
	  // return (<div style={{maxHeight: "80px", overflow: "auto"}}>{val}</div>);
	  return val;
	} // Should be Deprecated. Default empty data handler should be built in at table cell level
	/**
	 * Concat shipping address field to address string
	 *
	 * @param {object} input The object contains address fields. 
	*/
	convertAddress (input) {
	  let addr = "";
	
	  let name = '';
	  if (input.fullname) {
		name = input.fullname;
	  } else {
		name = input.firstname;
		if (input.lastname) name += ' ' + input.firstname;
	  }
	
	  if (name) {
		addr += name;
		addr += ", ";
	  }
	  if (input.address) {
		addr += input.address;
		addr += ", ";
	  }
	  if (input.city) {
		addr += input.city;
		addr += ", ";
	  }
	  if (input.province) {
		addr += input.province;
		addr += ", ";
	  }
	  if (input.postalcode) {
		addr += input.postalcode;
	  }
	  if (input.countrycode) {
		addr += input.countrycode;
	  }
	  if (addr[addr.length-1] === " ") addr = addr.slice(0, addr.length-2);
	  return addr;
	}
	/**
	 * Convert shipping method code to label
	 *
	 * @param {string} code The shipping method code. 
	*/
	convertShippingMethod (code) {
	  let lbl = shippingMethodMap[code];

	  // if has backend method map, use backend map
	  let backendMethodList = localStorage.getItem('method_list') ? JSON.parse(localStorage.getItem('method_list')) : [];
	  let backEndMap = {};
	  for (let method of backendMethodList) {
		backEndMap[method.method] = method.name;
	  }
   
	  if (backendMethodList.length > 0) {
		lbl = backEndMap[code];
	  }

	  if (lbl === undefined) {
		// if code doesn't match any label, just display code
		return code;
	  }
	  return lbl;
	}
	/**
	 * Convert PO status code to label
	 *
	 * @param {string} code The PO status code. 
	*/
	convertPOStatus (code) {
	  code = parseInt(code);
	  let codeMap = {
		2:"Pending Received",
		4:"Received",
		7:"Receiving",
		10: 'Pending Putaway',
		11: 'Putaway',
	  };
	
	  return codeMap[code] ? codeMap[code] : '';
	} // Should be Deprecated. Status code will be changed to readable words later
	/**
	 * Convert boolean to YES/ NO string
	 *
	 * @param {string} value The code to be converted. 
	*/
	convertBooleanToString (value) {
	  switch (true) {
		case value === 1:
		case value === "1":
		case value === true:
		case value === "true":
		case value === "true":
		  return "YES";
		default:
		  return "NO"
	  }
	} // Should be Deprecated. When all true false status are unified, this will not be needed
	/**
	 * Convert order status code to readable words
	 *
	 * @param {string || number} code The code to be converted. 
	*/
	convertOrderStatus (code) {
	  code = parseInt(code);
	  return orderStatusMap[code] ? orderStatusMap[code] : code;
	} // Should be Deprecated. Later order status will be converted to readable words
	/**
	 * convert shoprt ship and back order code to label
	 *
	 * @param {string} code The code to be converted. 
	*/
	convertShortShip (code) {
	  let code_map = {
		back_order: 'Short Ship',
		out_of_stock: 'Back Order',
	  };
	  return code_map[code] ? code_map[code] : code;
	} // Should be Deprecated. This is not very reusable
}

const utils = new Utils();
export default utils;