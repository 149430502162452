import React, { Component } from 'react';
import PropTypes from 'prop-types'
import axios from 'axios';
import { Typography, Grid, List, ListItem, IconButton, FormControlLabel, Checkbox, TextField, InputLabel, Select, FormControl, MenuItem, withStyles } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {
  NewDataTable,
  CardContainer,
  Button,
  CustomInput,
  GridItem,
  ReversedIconButton 
} from 'components';
import DataContext from 'context/Data'
import {
  ExposureNeg1,
  DeleteForever
} from '@material-ui/icons';
import utils from 'utils/utils'
import _ from 'lodash';
import ReturnTicketDialog from 'views/Dialogs/ReturnTicket'
import TakePictureDialog from 'views/Dialogs/Webcam/TakePicture'
import {withTimer} from 'hoc'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  nonSelectable: {
    userSelect: 'none'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  customInput: {
    margin: theme.spacing(0.5, 0),
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableImage: {
    height: '40px',
    width: '40px',
  },
  title: {
    flex: '0 0 auto',
  },
  button: {
    marginTop: theme.spacing(1)
  },
  customInputContainer: {
    margin: '0 !important',
    padding: 0
  },
  customInputNoMargin: {
    margin: '0 !important',
    padding: 0
  },
  menuItemText: {
    whiteSpace: 'break-spaces',
    wordWrap: 'break-word',
    // ['@media (min-width:780px)']:
    [theme.breakpoints.down('md')]: {
      maxWidth: "400px",
    },
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: "600px",
    },
    [theme.breakpoints.up('lg')]: {
      // maxWidth: "700px",
    },
  },
});

class OrderReturn extends Component {
  static contextType = DataContext;

  static propTypes = {
    classes: PropTypes.object.isRequired,
    timer: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.input_refs = {
      sku: null,
      supply: null,
      supply_box: null
    };
    this.temp_data = {
      sku_map: {},
      upc_map: {},
      barcode_map: {},
      key_array: [],
      top_item: '',
      focus_sku: false,
			active_row_id: '',
    };

    this.state = {
      loading: false,
      dialog: '',
      // return order search
      search_type: 'order_id',
      search_text: '',
      // return order search result
      comment: null,
      order_list: [],
      items: null,
      display_type: '',
      // return submit input fields 
      pallet_id: '',
      rma: '',
      notes: '',
      supply_list: [],
      take_pictures: [],
      // Data after submit
      return_ticket: [],
    };

    document.title = "Return";
  }

  componentDidMount() {
    // this.props.timer.start();
  }

  resetPage = () => {
    this.temp_data = {
      sku_map: {},
      upc_map: {},
      key_array: [],
      supply: null,
      supply_box: null,
      top_item: '',
      focus_sku: false,
			active_row_id: '',
    };

    this.setState({
      dialog: '',
      // return order search
      search_type: 'order_id',
      search_text: '',
      // return order search result
      comment: null,
      order_list: [],
      items: null,
      display_type: '',
      // return submit input fields 
      pallet_id: '',
      rma: '',
      notes: '',
      supply_list: [],
      take_pictures: [],
      // Data after submit
      return_ticket: []
    });
  }

  handleSkuScanned = (e) => {
    let scanned_val = utils.formatString(this.input_refs.sku?.value); 
    if (!scanned_val) return;

    if (this.temp_data.barcode_map[scanned_val]) scanned_val = this.temp_data.barcode_map[scanned_val];

    let matched_id = '';
    if (this.temp_data.sku_map[scanned_val]) matched_id = this.temp_data.sku_map[scanned_val]; // sku matched
    else if (this.temp_data.upc_map[scanned_val]) matched_id = this.temp_data.upc_map[scanned_val]; // upc matched
    else {
      this.context.alert(`The scanned item ${scanned_val} is not in this Order.`);
      if (this.input_refs.sku) {
        this.input_refs.sku.value = '';
        this.input_refs.sku.focus();
      }  
      return;
    }
    let items = Object.assign({}, this.state.items);
    let newItem = Object.assign({}, items[matched_id]);
    let newQty = 1 + parseInt(newItem.quantity_input);
    if (newQty > (parseInt(newItem.quantity) - parseInt(newItem.returned))) {
      this.context.alert("You can return at most " + newItem['quantity'] + " of this item");
      newQty = newItem['quantity'];
    }
    newItem['quantity_input'] = newQty;
    // if quantity changed, set status to 213, returned before, but trying to return it again, then guess who is 213
    if (parseInt(newItem.item_status) === 13) {
      newItem.item_status = 213;
    }
    items[matched_id] = newItem;
    // Move the scanned row to top
    this.temp_data.top_item = matched_id;

    if (this.input_refs.sku) {
      this.input_refs.sku.value = '';
      this.input_refs.sku.focus();
    }
    this.setState({items: items});
  }
  handleScan = _.debounce(this.handleSkuScanned, 150);
  addSupply = () => {
    let supply = utils.formatString(this.input_refs.supply.value);
    if (!supply) return;
    let newSupplies = Array.from(this.state.supply_list);
    newSupplies.push({sku: supply});
    this.setState({supply_list: newSupplies});
    this.input_refs.supply.value = '';
    this.input_refs.supply.focus();
    // this.temp_data.scanning_box = false;
    this.temp_data.scanning_box = true;
  }
  supplyScan = _.debounce(this.addSupply, 150)

  orderSearch = () => {
    const {search_type, search_text} = this.state;
    let err = '';
    if (!search_type) err += 'Search Type is required. \n';
    if (!search_text) err += 'Search Text is required. \n';

    if (err) {
      this.context.alert(err);
      return;
    }

    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/return/${search_type}/${search_text}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });

    this.setState({loading: true, order_list: [], display_type: '', comment: null, items: null, pallet_id: '', notes: '', rma: '',});
    this.temp_data.active_row_id = '';
    req.then(this.orderSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  orderSearchByID = (order_id) => {
    let req = axios({
      method: 'get',
      url: `${utils.getBaseUrl('customer')}/return/order_id/${order_id}`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
    });
    this.setState({loading: true, items: {}});
    
    req.then(this.orderSearchSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  orderSearchSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    // Some clients, for now it's only 204, 
    this.temp_data.barcode_map = (!resp.data.barcode_map || resp.data.barcode_map.length == 0) ? {} : resp.data.barcode_map;
    let result = resp.data;
    let type = result.type;
    let item_list = {};
    if (type === 'order_id' || type === 'tracking') {
      // search result is a single order, can start return
      let skus = {};
      let upcs = {};
      let keys = [];
      for (let item of result.data) {
        if (!item.polybagged_quantity) item.polybagged_quantity = 0;
        if (!item.barcoded_quantity) item.barcoded_quantity = 0;
        if (!item.steam_quantity) item.steam_quantity = 0;
        if (!item.hangtagged_quantity) item.hangtagged_quantity = 0;
        if (!item.reason) item.reason = "";
        if (!item.condition) item.condition = "";
				if (!item.take_picture) item.take_picture = null;
        // if has return_reason damaged, set default condition

        if (item.return_reason) {
          if (item.return_reason.toUpperCase().includes('DAMAGE')) {
            // if backend passed damaged, set reason to none condition to DAMAGED and can't change them
            item.condition = "DAMAGED";
            item.reason = "none";
            // Only for quince, they can't change condition and reason
            if (localStorage.getItem('customer_id') == 213)
            {
              item.no_edit = true;  
            }
          }
        }
        if (!item.quantity_input) item.quantity_input = 0;
        let sku = item.sku;
        let upc = item.upc;
        if (!skus[sku]) skus[sku] = item.item_id;
        if (!upcs[upc]) upcs[upc] = item.item_id;
        keys.push(item.item_id);
        item_list[item.item_id] = item;
      }
      this.temp_data.sku_map = skus;
      this.temp_data.upc_map = upcs;
      this.temp_data.key_array = keys;
      this.setState({items: item_list, display_type: 'single', comment: result.comment || null});
      // start timer only when single return is loaded
      this.props.timer.start();
      this.temp_data.focus_sku = true;
      // If this return has message, opo up alert
      if (resp.data.message) {
        this.context.alert(resp.data.message);
      }
    } else {
      this.setState({order_list: result.data, display_type: 'list'});
    }
    
  }
  printBarcode = (id, label) => {
    let items = Object.assign({}, this.state.items);
    let item = items[id];

    let content = item[label];
    let qty = 1;
    if (parseInt(item.barcoded_quantity) > 0) {
        qty = parseInt(item.barcoded_quantity);
    }

    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/barcode`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data: {
        type: 'product_tag',
        codes: content,
        repeat: qty
      },
    });

    this.setState({loading: true});
    req.then(this.printSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert}));
  }
  printSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }

    let data = resp.data;
    utils.printPage(data);
  }
  submitReturn = (e) => {
    e.preventDefault();
    if (parseInt(this.context.customer.get()) !== parseInt(localStorage.getItem('customer_id'))) {
      this.context.alert("Customer has been changed to " + localStorage.getItem('customer_name') + ", please refresh the page and try again!");
      return;
    }
    const { items, notes, rma, pallet_id, take_pictures } = this.state;
    let err = '';
    let req_items = [];
    let order_id = '';

    for (let item_id in items) {
      let single_item = Object.assign({}, items[item_id]);
      if (single_item.order_id) order_id = single_item.order_id;
      if (parseInt(single_item.quantity_input) === 0) continue;
      if (!single_item.reason) err += `Please select return reason for SKU: ${single_item.sku}. \n`;
      if (!single_item.condition) err += `Please select product condition for SKU: ${single_item.sku}. \n`;
      let submit_item = Object.assign(single_item, {
        returned: parseInt(single_item.returned) || 0,
        return_qty: parseInt(single_item.return_qty) || 0,
        barcoded_quantity: parseInt(single_item.barcoded_quantity) || 0,
        polybagged_quantity: parseInt(single_item.polybagged_quantity) || 0,
        steam_quantity: parseInt(single_item.steam_quantity) || 0,
        hangtagged_quantity: parseInt(single_item.hangtagged_quantity) || 0,
      });
      switch (true) {
        case submit_item.barcoded_quantity > submit_item.quantity:
        case submit_item.polybagged_quantity > submit_item.quantity:
        case submit_item.steam_quantity > submit_item.quantity:
        case submit_item.hangtagged_quantity > submit_item.quantity:
          err += "SKU: "+ single_item.sku + ", Barcoded, Polybagged, Steaming and Hangtagged quantity can not exceed Order Quantity!\n";
          break;
      }
      // Todo, might need to limit steam bagged, and barcoded to quantity input
      req_items.push(submit_item);
    }
    if (req_items.length === 0) err += `You haven't returned any items.\n`;
    if (!pallet_id) err += 'Pallet ID is required.\n';

    if (err) this.context.alert(err);
    else {
      this.submitReturnOnce({
        pallet: pallet_id,
        notes: notes,
        rma: rma,
        order_id: order_id,
        items: req_items,
        supply: this.state.supply_list.map((elem)=>elem.sku).join(','), // todo might need change to pass an array 
        take_pictures: take_pictures,
      });
    }
  }
  submitReturnAJax = (data) => {
    this.setState({loading: true})
    let req = axios({
      method: 'post',
      url: `${utils.getBaseUrl('customer')}/return`,
      headers: {
        token: localStorage.getItem('token'), user: localStorage.getItem('user_id'), username: localStorage.getItem('username'), customer: localStorage.getItem('customer_id'), warehouse: localStorage.getItem('warehouse_id')
      },
      data,
    });

    req.then(this.submitReturnSuccess).catch(utils.defaultErrorCallBack.bind(this, {alert: this.context.alert, errorCallback: this.resetSubmitOnce}));
    this.setState({loading: true})
  }
  resetSubmitOnce = () => {this.submitReturnOnce = _.once(this.submitReturnAJax);} // reset the submit picking once after the ajax call returns
  submitReturnOnce = _.once(this.submitReturnAJax)
  submitReturnSuccess = (resp) => {
    this.setState({loading: false});
    this.resetSubmitOnce();
    if (resp.data.Error) {
      this.context.alert(resp.data.Error);
      return;
    }
    let result = resp.data;

    this.context.snackDisplay("Return submit success!");
    if (Array.isArray(result) && result.length > 0) {
      this.setState({
        dialog: 'return_ticket',
        return_ticket: result
      });
    } else {
      // window.location.reload();
      this.resetPage();
    }
  }

  renderCommentTable = () => {
    const { items, comment, display_type } = this.state;
    if (!items || !comment || comment.length == 0 || display_type !== 'single') return null;

    let colSettings = [
      {
        key: 'username',
        label: 'Username',
      },
      {
        key: 'fullname',
        label: 'Fullname',
      },
      {
        key: 'comment',
        label: 'Comment',
        render: (val, key, row) => {
          let content = val ? val : '';
          if (content.includes("https://")) {
            let btn_text = "See PDF";
            if (content.includes(".pdf")) 
            {
              btn_text = "See PDF";
            } else 
            {
              btn_text = "See Image";
            }
            let file_name = content.split("import/");
            if (file_name.length > 1)
            {
              btn_text = utils.removeFilePrefix(file_name[1]);
            } 
            
            return (<Button onClick={()=>{
                let newTab = window.open(content);
                if (!newTab) this.context.alert("Unable to open new tab, please change browser settings to allow pop-up.");
            }}>{btn_text}</Button>);
          }
          return content;
        }
      },
      {
        key: 'date',
        label: 'Date/Time',
        render: utils.localizeDate,
      }
    ];

    return (
      <GridItem xs={12}>
        <div style={{marginTop: '1rem'}} className={this.props.classes.root}>
          <NewDataTable
            rows={comment}
            columns={colSettings}
            noPagination
            withPaper={false}
          />
        </div>
      </GridItem>
    );
  }
  renderReturnForm = () => {
    const {classes} = this.props;
    const {items, display_type} = this.state;
    if (!items || display_type !== 'single') return;

    return (
      <GridItem xs={12} sm={6}>
      <CardContainer>
        <CustomInput
          labelText='Pallet'
          formControlProps={{
            fullWidth: true,
            required: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>this.setState({pallet_id: e.target.value}),
            value: this.state.pallet_id,
          }}
        />

        <CustomInput
          labelText='Notes'
          formControlProps={{
            fullWidth: true,
            className: classes.customInput
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>this.setState({notes: e.target.value}),
            value: this.state.notes,
          }}
        />

        <CustomInput
          labelText='RMA'
          formControlProps={{
            fullWidth: true,
            // required: true,
            className: classes.customInput,
          }}
          labelProps={{
            shrink: true,
          }}
          inputProps={{
            onChange: (e)=>this.setState({rma: e.target.value}),
            value: this.state.rma,
          }}
        />

        <Button onClick={this.submitReturn} className={classes.button}>Submit Return</Button>
      </CardContainer>
    </GridItem>
    );
  }
  renderSearchResult = () => {
    const { order_list, items, display_type } = this.state;
    let btnBack = null;
    let content = null;
    if (!display_type) return null;
    if (items && display_type == 'single') {
      if (order_list && order_list.length > 0) btnBack = <Button style={{marginBottom: '1rem'}} onClick={()=>{this.setState({display_type:'list'})}}>Go Back</Button>;
      content = this.renderOrderSingleTable();
    } else content = this.renderOrderListTable();

    return (
      <GridItem xs={12}>
        <CardContainer>
          {btnBack}
          {content}
        </CardContainer>
      </GridItem>
    );
  }
  renderOrderListTable = () => {
    const {classes} = this.props;
    const {order_list} = this.state;

    let columns = [
      {
        key: 'order_key',
        label: 'OrderKey',
        width: 'auto',
        render: (val, key, row) => {
          return (
            <Button color='default' onClick={this.orderSearchByID.bind(this, row.order_id)}>{val}</Button>
          );
        }
      },
      {
        key: 'address',
        label: 'Address',
        width: 'auto',
        contentNoWrap: true,
        render: (val, key, row)=>{
          var addr = "";
          var name = row.fullname;
          if (name) {
              addr += name;
              addr += ", ";
          }
          if (row.company) {
              addr += row.company;
              addr += ", ";
          }
          if (row.addressline1) {
              addr += row.addressline1;
              addr += ", ";
          }
          if (row.addressline2) {
              addr += row.addressline2;
              addr += ", ";
          }
          if (row.city) {
              addr += row.city;
              addr += ", ";
          }
          if (row.province) {
              addr += row.province;
              addr += ", ";
          }
          if (row.postalcode) {
              addr += row.postalcode;
              addr += ", ";
          }
          if (row.countrycode) {
              addr += row.countrycode;
              addr += ", ";
          }
          if (row.phonenumber) {
              addr += row.phonenumber;
          }
  
          if (addr[addr.length-1] === " ") addr = addr.slice(0, addr.length-2);
          return addr;
        }
      },
      {
        key: 'sku',
        render: (val, key, row) => {
          return <span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span>
        },
        contentNoWrap: true,
        width: 'auto',
        label: 'Items',
      },
    ];

    return (
      <NewDataTable
        rows={order_list}
        // rowHeight={50} 
        maxHeight={500}
        noPagination
        columns={columns}
      />
    );
  }
  renderOrderSingleTable = () => {
    const { classes } = this.props;
    const { items } = this.state;

    if (!items || !this.temp_data.key_array) return null;
    let rows = [];
    
    // top item
    if (this.temp_data.top_item) rows.push(items[this.temp_data.top_item]);

    for (let id of this.temp_data.key_array) {
      if (this.temp_data.top_item === id) continue;
      rows.push(items[id]);
    }
    if (rows.length === 0) return null;

    let columns = [
      {
        key: 'order_id',
        label: 'OrderBarcode',
        width: 'auto',
      },
      {
        key: 'sku',
        label: 'SKU',
        render: (val, key, row) => {
          return (
            <Button color='default' onClick={this.printBarcode.bind(this, row.item_id, key)}><span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span></Button>
          );
        }
      },
      {
        key: 'upc',
        label: 'Upc',
        render: (val, key, row) => {
          if (!val) return null;
          return (
            <Button color='default' onClick={this.printBarcode.bind(this, row.item_id, key)}><span className={!utils.checkAllowCopy() ? classes.nonSelectable : ''}>{val}</span></Button>
          );
        }
      },
      {
        key: 'name',
        label: 'ItemName',
      },
      {
        key: 'category',
        label: 'Category',
        contentNoWrap: true,
      },
      {
        key: 'image',
        label: 'Image',
        render: (val, key, row)=>{
          if (val) {
            return (<img className={classes.tableImage} alt="" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
              this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
            }} src={val}/>);
          } else {
            return "No Image";
          }
        }
      },
			// {
      //   key: 'take_picture',
      //   label: 'Take Picture',
      //   render: (val, key, row)=>{
      //     if (val) {
			// 			return (
			// 				<div style={{display:'inline-flex', alignItems: 'center'}}>
			// 					<img className={classes.tableImage} alt="" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
			// 						this.context.alert(<img src={val} alt="wrong url" style={{width: '100%'}} />, {type: row.sku || 'Product Image'});
			// 					}} src={val}/>

			// 					<span>
			// 						<IconButton color="secondary" size="small" onClick={()=>{
			// 								let new_items = items;
			// 								new_items[row.item_id]['take_picture'] = '';
			// 								this.setState({items: new_items});
			// 								}} variant="fab" aria-label="Delete">
			// 								<DeleteForever/>
			// 							</IconButton>
			// 					</span>
			// 				</div>
			// 			);
      //     } else {
            // return <Button color='default' onClick={()=>{
						// 	this.temp_data.active_row_id = row.item_id;
						// 	this.setState({
						// 		dialog: 'take_picture'
						// 	});
						// }}>Take Picture</Button>;
      //     }
      //   }
      // },
      {
        key: 'quantity',
        label: 'QTY',
      },
      {
        key: 'return_qty',
        label: 'EX-Return',
        render: (val)=>val || 0
      },
      {
        key: 'returned',
        label: 'Returned',
        render: (val, key, row)=>(row.returned ? parseInt(row.returned) : 0) + parseInt(row.quantity_input)
      },
      {
        key: 'quantity_input',
        label: 'QTY Input',
        render: (val, key, row) => {
          return (
            <span style={{display: "inline-flex", alignItems: 'center'}}>
              {val}
              {
                parseInt(val) > 0 &&
                <ReversedIconButton style={{marginLeft: ".5rem"}} size="small" onClick={()=>{
                  let newItems = Object.assign({}, items);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  let old_qty = parseInt(newItem.quantity_input);
                  let new_qty = old_qty -1;
                  if (new_qty < 0) {
                    this.context.alert("The quantity is already 0, can't minus 1.");
                    return;
                  }
                  newItem['quantity_input'] = new_qty;
                  newItems[row.item_id] = newItem;
                  this.setState({items: newItems});
                }} variant="fab" color="secondary" aria-label="Decrease" className={classes.button}>
                  <ExposureNeg1 style={{color: 'white'}}/>
                </ReversedIconButton>
              }
            </span>
          );
        },
      },
      {
        key: 'condition',
        label: 'Condition',
        width: 100,
        render: (val, key, row) => {
          if (row.no_edit) return val;

          return (
            <FormControl fullWidth required className={classes.customInput}>
              <Select
                 value={val}
                 onChange={(e)=>{
                  let newItems = Object.assign({}, items);
                  let newItem = Object.assign({}, newItems[row.item_id]);
                  if (newItem['condition'] != e.target.value) {
                    // if condition changed, reset reason 
                    newItem['reason'] = '';
                    if (e.target.value == 'GOOD') {
                      newItem['reason'] = 'none';
                    }
                  }
                  newItem['condition'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({items: newItems});
                 }}
              >
                <MenuItem value="GOOD">GOOD</MenuItem>
                <MenuItem value="DAMAGED">DAMAGED</MenuItem>
                {/* <MenuItem value="CLEAN">CLEAN</MenuItem> */}
              </Select>
            </FormControl>
          );
        }
      },
      {
        key: 'reason',
        label: 'Reason',
        width: 250,
        render: (val, key, row) => {
          if (row.no_edit) return 'NONE';

          return (
            <FormControl fullWidth required className={classes.customInput}>
              <Select
                 value={val}
                 disabled={!row.condition}
                 onChange={(e)=>{
                  let newItems = Object.assign({}, items);
                  let newItem = Object.assign({}, newItems[row.item_id]);
                  newItem['reason'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({items: newItems});
                 }}
              >
                {utils.getReturnReasonOptions(row.condition).map((elem)=><MenuItem key={elem.value} value={elem.value}>{elem.label}</MenuItem>)}
              </Select>
            </FormControl>
          );
        }
      },
      {
        key: 'barcoded_quantity',
        label: 'Barcoded',
        render: (val, key, row) => {
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: false,
              }}
              inputProps={{
                inputProps: {
                  step: 1,
                  min: 0,
                  max: row.quantity
                },
                type: 'number',
                value: val,
                onChange: (e)=>{
                  let newVal = e.target.value;
                  let newItems = Object.assign({}, items);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  if (parseInt(newVal) >= 1000) {
                    this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                  }
                  if (parseInt(newVal) > newItem.quantity) {
                    this.context.alert(`Warning: The value for SKU: ${newItem.sku}, can't exceed the QTY .`);
                      return;
                  }
                  newItem['barcoded_quantity'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({items: newItems});
                },
              }}
            />
          );
        }
      },
      {
        key: 'polybagged_quantity',
        label: 'Polybagged',
        render: (val, key, row) => {
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: false,
              }}
              inputProps={{
                inputProps: {
                  step: 1,
                  min: 0,
                  max: row.quantity
                },
                type: 'number',
                value: val,
                onChange: (e)=>{
                  let newVal = e.target.value;
                  let newItems = Object.assign({}, items);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  if (parseInt(newVal) >= 1000) {
                    this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                  }
                  if (parseInt(newVal) > newItem.quantity) {
                    this.context.alert(`Warning: The value for SKU: ${newItem.sku}, can't exceed the QTY .`);
                    return;
                  }
                  newItem['polybagged_quantity'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({items: newItems});
                },
              }}
            />
          );
        }
      },
      {
        key: 'steam_quantity',
        label: 'Steaming',
        render: (val, key, row) => {
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: false,
              }}
              inputProps={{
                inputProps: {
                  step: 1,
                  min: 0,
                  max: row.quantity
                },
                value: val,
                type: 'number',
                onChange: (e)=>{
                  let newVal = e.target.value;
                  let newItems = Object.assign({}, items);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  if (parseInt(newVal) >= 1000) {
                    this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                  }
                  if (parseInt(newVal) > newItem.quantity) {
                    this.context.alert(`Warning: The value for SKU: ${newItem.sku}, can't exceed the QTY .`);
                    return;
                  }
                  newItem['steam_quantity'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({items: newItems});
                },
              }}
            />
          );
        }
      },
      {
        key: 'hangtagged_quantity',
        label: 'Hangtagged',
        render: (val, key, row) => {
          return (
            <CustomInput
              formControlProps={{
                fullWidth: true,
                className: classes.customInput
              }}
              labelProps={{
                shrink: false,
              }}
              inputProps={{
                inputProps: {
                  step: 1,
                  min: 0,
                  max: row.quantity
                },
                value: val,
                type: 'number',
                onChange: (e)=>{
                  let newVal = e.target.value;
                  let newItems = Object.assign({}, items);
                  let newItem = Object.assign({}, newItems[row.item_id]);

                  if (parseInt(newVal) >= 1000) {
                    this.context.alert(`Warning: You are trying to enter a very large number for SKU: ${newItem.sku}, please check if it is a mistake.`);
                  }
                  if (parseInt(newVal) > newItem.quantity) {
                    this.context.alert(`Warning: The value for SKU: ${newItem.sku}, can't exceed the QTY .`);
                    return;
                  }
                  newItem['hangtagged_quantity'] = e.target.value;
                  newItems[row.item_id] = newItem;
                  this.setState({items: newItems});
                },
              }}
            />
          );
        }
      },
      {
        key: 'style',
        label: 'Style',
      },
      {
        key: 'size',
        label: 'Size',
      },
      {
        key: 'color',
        label: 'Color',
      },
    ];

    let rowSettings = {
      rowProps: {
        hover: false
      },
      classNameOnDisplay: (item, displayProps) => {
        let returned_qty = (item.returned ? parseInt(item.returned) : 0) + parseInt(item.quantity_input);
        let match_ex = item.match_ex;
        let qty_match = item['quantity'] ? parseInt(item['quantity']) : 0;
        // if (match_ex) {
        //   // matching to ex_return. return_qty is expect return quantity
        //   qty_match = item['return_qty'] ? parseInt(item['return_qty']) : 0;
        // }
        if (parseInt(item['return_qty']) && item['return_qty'] > 0) {
          // matching to ex_return. return_qty is expect return quantity
          qty_match = item['return_qty'];
        }

        let color = '';
        switch (true) {
          case returned_qty === 0:
              // white haven't start
              color = "";
              break;
          case (qty_match - parseInt(item['returned'])) === parseInt(item['quantity_input']):
              // green quantity matched
              color = "green";
              break;
          default:
              // yellow doesn't match
              // received too much
              // if ((qty_match - parseInt(item['returned'])) < parseInt(item['quantity_input'])) color = "yellow";
              // else color = 'light-red'; // received not enough
              // 03-13-2020 switched color: yellow and light-red
              if ((qty_match - parseInt(item['returned'])) > parseInt(item['quantity_input'])) color = "yellow";
              else color = 'light-red'; // received not enough
        }
        
        if (!displayProps) displayProps = {className: ''};
        let classes = displayProps.className || '';
        classes = classes.split(' ');
        classes.push(color);
        displayProps.className = classes.join(' ');
        return displayProps;
      }
    };

    _.delay(()=>{
      if (this.temp_data.focus_sku) {
        if (this.input_refs.sku) {
          this.input_refs.sku.focus();
          this.temp_data.focus_sku = false;
        }
      }
    }, 0);

    return (
      <div>
        <CustomInput
          labelText='SKU/UPC'
          formControlProps={{
              fullWidth: false,
              style: {marginLeft: '.25rem', marginRight: '.75rem'},
              className: classes.customInput
          }}
          labelProps={{
              shrink: true,
          }}
          inputProps={{
              onKeyUp: this.handleScan,
              onPaste: (e)=>{
                if (!utils.checkAllowCopy()) e.preventDefault();
              },
              inputRef: elem=>this.input_refs.sku = elem
          }}
        />

        <NewDataTable
          rows={rows}
          // rowHeight={50} 
          maxHeight={500}
          rowSettings={rowSettings}
          noPagination
          columns={columns}
        />
      </div>
    );
  }
  renderSupplyTable = () => {
    const {classes} = this.props;
    const {supply_list, display_type, items} = this.state;
    if (!items || display_type !== 'single') return;

    let columns = [
      {
        key: 'sku',
        label: 'SKU',
        contentNoWrap: true,
      }, {
        key: 'delete',
        label: 'Delete',
        render: (sku, key, product, index)=>{
          return (
            <span>
             <IconButton color="secondary" size="small" onClick={()=>{
               let newList = Array.from(this.state.supply_list);
               newList.splice(index, 1);
               this.setState({supply_list: newList});
               }} variant="fab" aria-label="Delete">
                <DeleteForever/>
              </IconButton>
            </span>
          );
        },
      }
    ];

    return (
      <GridItem xs={12} sm={6}>
        <CardContainer>
          <div className={this.props.classes.root}>
            <div style={{marginBottom: '1rem'}} className={classes.title} >
              <Typography variant="h6">
                Scanned Supplies
              </Typography>

              <CustomInput
                labelText='Supply'
                formControlProps={{
                  fullWidth: true,
                  className: classes.customInput
                }}
                labelProps={{
                  shrink: true,
                }}
                inputProps={{
                  placeholder: "Scan to add supply",
                  onKeyUp: this.supplyScan,
                  // onFocus: ()=>{this.temp_data.un_focus = false; this.temp_data.scanning_box = true; },
                  // onBlur: ()=>{this.temp_data.un_focus = true;},
                  disabled: this.state.loading,
                  inputRef: elem => this.input_refs.supply = elem
                }}
              />
            </div>

            <NewDataTable
              rows={supply_list}
              columns={columns}
              noPagination
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  renderPictureTable = () => {
    const {classes} = this.props;
    const {take_pictures, display_type, items} = this.state;
    if (!items || display_type !== 'single') return;

    let columns = [
      {
        key: 'picture',
        label: 'Picture',
        contentNoWrap: true,
        render: (val, key, row)=>{
          return (<img className={classes.tableImage} alt="" title='click to zoom' style={{cursor: 'pointer'}} onClick={()=>{
            this.context.alert(<img src={row} alt="wrong url" style={{width: '100%'}} />, 'Return Picture');
          }} src={row}/>);
        }
      }, {
        key: 'delete',
        label: 'Delete',
        render: (sku, key, product, index)=>{
          return (
            <span>
             <IconButton color="secondary" size="small" onClick={()=>{
               let newList = Array.from(this.state.take_pictures);
               newList.splice(index, 1);
               this.setState({take_pictures: newList});
               }} variant="fab" aria-label="Delete">
                <DeleteForever/>
              </IconButton>
            </span>
          );
        },
      }
    ];

    return (
      <GridItem xs={12} sm={6}>
        <CardContainer>
          <div className={this.props.classes.root}>
            <div style={{marginBottom: '1rem'}} className={classes.title} >
              <Typography variant="h6">
                Return Pictures
              </Typography>

              <Button color='default' onClick={()=>{
                this.setState({
                  dialog: 'take_picture'
                });
              }}>Take Picture</Button>
            </div>

            <NewDataTable
              rows={take_pictures}
              columns={columns}
              noPagination
            />
          </div>
        </CardContainer>
      </GridItem>
    );
  }
  renderDialog = () => {
    switch (this.state.dialog)  {
      case 'return_ticket':
        return (
          <ReturnTicketDialog
            handleSubmit={()=>{
              this.resetPage();
              this.setState({dialog: ''});
              // window.location.reload();
            }}
            handleClose={()=>{
              this.resetPage();
              this.setState({dialog: ''});
              // window.location.reload();
            }}
            returnTicket={this.state.return_ticket}
          />
        );
        break;
      case 'take_picture':
        // item level pictures
				// return (
        //   <TakePictureDialog
        //     handleSubmit={(picture)=>{
        //       // use this.temp_data.active_row_id update product picture
				// 			let new_items = this.state.items;
				// 			let active_item = new_items[this.temp_data.active_row_id];
				// 			if (active_item) {
				// 				active_item.take_picture = picture;
				// 				new_items[this.temp_data.active_row_id] = active_item;
				// 			}
							
        //       this.setState({dialog: '', items: new_items});
				// 			this.temp_data.active_row_id = '';
        //     }}
        //     handleClose={()=>{
        //       this.setState({dialog: ''});
        //       this.temp_data.active_row_id = '';
        //     }}
        //     returnTicket={this.state.return_ticket}
        //   />
        // );

        // Order level pictures
        return (
          <TakePictureDialog
            handleSubmit={(picture)=>{
              // use this.temp_data.active_row_id update product picture
							let new_pictures = Array.from(this.state.take_pictures);
							new_pictures.push(picture);
							
              this.setState({dialog: '', take_pictures: new_pictures});
            }}
            handleClose={()=>{
              this.setState({dialog: ''});
            }}
            returnTicket={this.state.return_ticket}
          />
        );
        break;
      default:
        return null;
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container spacing={2}>
        {this.state.loading && <div className='bxz-loading-bar'>Loading&#8230;</div>}

        {this.renderDialog()}

        <GridItem xs={12} sm={6}>
          <CardContainer>
          <MuiAlert severity="info">Total work time on this page: {new Date(this.props.timer.get() * 1000).toISOString().substring(11, 19)}</MuiAlert>
          <br/>

            <FormControl fullWidth required className={classes.customInput} style={{paddingBottom: '1px'}}>
              <InputLabel shrink>Search Type</InputLabel>
              <Select
                 value={this.state.search_type}
                 onChange={(e)=>this.setState({search_type: e.target.value})}
              >
                <MenuItem value='order_id'>Order, Barcode, Return Tracking</MenuItem>
                <MenuItem value='zipcode'>Zip Code</MenuItem>
                <MenuItem value='phonenumber'>Phone Number</MenuItem>
                <MenuItem value='name'>Name</MenuItem>
                <MenuItem value='tracking'>Shipment Tracking</MenuItem>
              </Select>
            </FormControl>

            <CustomInput
              labelText='Search Text'
              formControlProps={{
                fullWidth: true,
                required: true,
                className: classes.customInput,
              }}
              labelProps={{
                shrink: true,
              }}
              inputProps={{
                onChange: (e)=>this.setState({search_text: e.target.value}),
                value: this.state.search_text,
              }}
            />

            <Button onClick={this.orderSearch} className={classes.button}>Search</Button>

            {this.renderCommentTable()}
          </CardContainer>
        </GridItem>

        {this.renderReturnForm()}
        {this.renderSupplyTable()}
        {this.renderPictureTable()}
        {this.renderSearchResult()}
      </Grid>
    );
  }
}
export default withTimer(withStyles(styles)(OrderReturn));
